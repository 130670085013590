@use 'sass:map';
@use '@angular/material' as mat;
@include mat.core();

	$red: (
        50: #fad4d8,
		100: #ffcdd2,
		200: #ef9a9a,
		300: #e57373,
		400: #ef5350,
		500: #f44336,
		600: #e53935,
		700: #d32f2f,
		800: #c62828,
		900: #b71c1c,
		A100: #ff8a80,
		A200: #ff5252,
		A400: #ff1744,
		A700: #d50000,
		contrast: (
			50: rgba(0, 0, 0, 0.87),
			100: rgba(0, 0, 0, 0.87),
			200: rgba(0, 0, 0, 0.87),
			300: rgba(255, 255, 255, 1),
			400: rgba(255, 255, 255, 1),
			500: rgba(255, 255, 255, 1),
			600: rgba(255, 255, 255, 1),
			700: rgba(255, 255, 255, 1),
			800: rgba(255, 255, 255, 1),
			900: rgba(255, 255, 255, 1),
			A100: rgba(0, 0, 0, 0.87),
			A200: rgba(255, 255, 255, 1),
			A400: rgba(255, 255, 255, 1),
			A700: rgba(255, 255, 255, 1),
		)
    );

    $pink: (
        50: #fce4ec,
		100: #f8bbd0,
		200: #f48fb1,
		300: #f06292,
		400: #ec407a,
		500: #e91e63,
		600: #d81b60,
		700: #c2185b,
		800: #ad1457,
		900: #880e4f,
		A100: #ff80ab,
		A200: #ff4081,
		A400: #f50057,
		A700: #c51162,
		contrast: (
			50: rgba(0, 0, 0, 0.87),
			100: rgba(0, 0, 0, 0.87),
			200: rgba(0, 0, 0, 0.87),
			300: rgba(0, 0, 0, 0.87),
			400: rgba(0, 0, 0, 0.87),
			500: rgba(255, 255, 255, 1),
			600: rgba(255, 255, 255, 1),
			700: rgba(255, 255, 255, 1),
			800: rgba(255, 255, 255, 1),
			900: rgba(255, 255, 255, 1),
			A100: rgba(0, 0, 0, 0.87),
			A200: rgba(255, 255, 255, 1),
			A400: rgba(255, 255, 255, 1),
			A700: rgba(255, 255, 255, 1)
		)
    );

    $purple: (
		50: #f1daf5,
		100: #e1bee7,
		200: #ce93d8,
		300: #ba68c8,
		400: #ab47bc,
		500: #9c27b0,
		600: #8e24aa,
		700: #7b1fa2,
		800: #6a1b9a,
		900: #4a148c,
		A100: #ea80fc,
		A200: #e040fb,
		A400: #d500f9,
		A700: #aa00ff,
		contrast: (
			50: rgba(0, 0, 0, 0.87),
			100: rgba(0, 0, 0, 0.87),
			200: rgba(0, 0, 0, 0.87),
			300: rgba(0, 0, 0, 0.87),
			400: rgba(0, 0, 0, 0.87),
			500: rgba(255, 255, 255, 1),
			600: rgba(255, 255, 255, 1),
			700: rgba(255, 255, 255, 1),
			800: rgba(255, 255, 255, 1),
			900: rgba(255, 255, 255, 1),
			A100: rgba(0, 0, 0, 0.87),
			A200: rgba(255, 255, 255, 1),
			A400: rgba(255, 255, 255, 1),
			A700: rgba(255, 255, 255, 1)
		)
	);

	$deep-purple: (
		50: #ede7f6,
		100: #d1c4e9,
		200: #b39ddb,
		300: #9575cd,
		400: #7e57c2,
		500: #673ab7,
		600: #5e35b1,
		700: #512da8,
		800: #4527a0,
		900: #311b92,
		A100: #b388ff,
		A200: #7c4dff,
		A400: #651fff,
		A700: #6200ea,
		contrast:(
			50: rgba(0, 0, 0, 0.87),
			100: rgba(0, 0, 0, 0.87),
			200: rgba(0, 0, 0, 0.87),
			300: rgba(255, 255, 255, 1),
			400: rgba(255, 255, 255, 1),
			500: rgba(255, 255, 255, 1),
			600: rgba(255, 255, 255, 1),
			700: rgba(255, 255, 255, 1),
			800: rgba(255, 255, 255, 1),
			900: rgba(255, 255, 255, 1),
			A100: rgba(0, 0, 0, 0.87),
			A200: rgba(255, 255, 255, 1),
			A400: rgba(255, 255, 255, 1),
			A700: rgba(255, 255, 255, 1)
		)
	);

	$indigo: (
		50: #e8eaf6,
		100: #c5cae9,
		200: #9fa8da,
		300: #7986cb,
		400: #5c6bc0,
		500: #3f51b5,
		600: #3949ab,
		700: #303f9f,
		800: #283593,
		900: #1a237e,
		A100: #8c9eff,
		A200: #536dfe,
		A400: #3d5afe,
		A700: #304ffe,
		contrast: (
			50: rgba(0, 0, 0, 0.87),
			100: rgba(0, 0, 0, 0.87),
			200: rgba(0, 0, 0, 0.87),
			300: rgba(255, 255, 255, 1),
			400: rgba(255, 255, 255, 1),
			500: rgba(255, 255, 255, 1),
			600: rgba(255, 255, 255, 1),
			700: rgba(255, 255, 255, 1),
			800: rgba(255, 255, 255, 1),
			900: rgba(255, 255, 255, 1),
			A100: rgba(0, 0, 0, 0.87),
			A200: rgba(255, 255, 255, 1),
			A400: rgba(255, 255, 255, 1),
			A700: rgba(255, 255, 255, 1)
		)
	);

	$blue: (
		50: #e3f2fd,
		100: #bbdefb,
		200: #90caf9,
		300: #64b5f6,
		400: #42a5f5,
		500: #2196f3,
		600: #1e88e5,
		700: #1976d2,
		800: #1565c0,
		900: #0d47a1,
		A100: #82b1ff,
		A200: #448aff,
		A400: #2979ff,
		A700: #2962ff,
		contrast: (
			50: rgba(0, 0, 0, 0.87),
			100: rgba(0, 0, 0, 0.87),
			200: rgba(0, 0, 0, 0.87),
			300: rgba(0, 0, 0, 0.87),
			400: rgba(0, 0, 0, 0.87),
			500: rgba(255, 255, 255, 1),
			600: rgba(255, 255, 255, 1),
			700: rgba(255, 255, 255, 1),
			800: rgba(255, 255, 255, 1),
			900: rgba(255, 255, 255, 1),
			A100: rgba(0, 0, 0, 0.87),
			A200: rgba(255, 255, 255, 1),
			A400: rgba(255, 255, 255, 1),
			A700: rgba(255, 255, 255, 1)
		)
	);

	$light-blue: (
		50: #e1f5fe,
		100: #b3e5fc,
		200: #81d4fa,
		300: #4fc3f7,
		400: #29b6f6,
		500: #03a9f4,
		600: #039be5,
		700: #0288d1,
		800: #0277bd,
		900: #01579b,
		A100: #80d8ff,
		A200: #40c4ff,
		A400: #00b0ff,
		A700: #0091ea,
		contrast: (
			50: rgba(0, 0, 0, 0.87),
			100: rgba(0, 0, 0, 0.87),
			200: rgba(0, 0, 0, 0.87),
			300: rgba(0, 0, 0, 0.87),
			400: rgba(0, 0, 0, 0.87),
			500: rgba(255, 255, 255, 1),
			600: rgba(255, 255, 255, 1),
			700: rgba(255, 255, 255, 1),
			800: rgba(255, 255, 255, 1),
			900: rgba(255, 255, 255, 1),
			A100: rgba(0, 0, 0, 0.87),
			A200: rgba(0, 0, 0, 0.87),
			A400: rgba(0, 0, 0, 0.87),
			A700: rgba(255, 255, 255, 1)
		)
	);

	$cyan: (
		50: #e0f7fa,
		100: #b2ebf2,
		200: #80deea,
		300: #4dd0e1,
		400: #26c6da,
		500: #00bcd4,
		600: #00acc1,
		700: #0097a7,
		800: #00838f,
		900: #006064,
		A100: #84ffff,
		A200: #18ffff,
		A400: #00e5ff,
		A700: #00b8d4,
		contrast:( 
			50: rgba(0, 0, 0, 0.87),
			100: rgba(0, 0, 0, 0.87),
			200: rgba(0, 0, 0, 0.87),
			300: rgba(0, 0, 0, 0.87),
			400: rgba(0, 0, 0, 0.87),
			500: rgba(255, 255, 255, 1),
			600: rgba(255, 255, 255, 1),
			700: rgba(255, 255, 255, 1),
			800: rgba(255, 255, 255, 1),
			900: rgba(255, 255, 255, 1),
			A100: rgba(0, 0, 0, 0.87),
			A200: rgba(0, 0, 0, 0.87),
			A400: rgba(0, 0, 0, 0.87),
			A700: rgba(0, 0, 0, 0.87)
		)
	);

	$teal: (
		50: #e0f2f1,
		100: #b2dfdb,
		200: #80cbc4,
		300: #4db6ac,
		400: #26a69a,
		500: #009688,
		600: #00897b,
		700: #00796b,
		800: #00695c,
		900: #004d40,
		A100: #a7ffeb,
		A200: #64ffda,
		A400: #1de9b6,
		A700: #00bfa5,
		contrast: (
			50: rgba(0, 0, 0, 0.87),
			100: rgba(0, 0, 0, 0.87),
			200: rgba(0, 0, 0, 0.87),
			300: rgba(0, 0, 0, 0.87),
			400: rgba(0, 0, 0, 0.87),
			500: rgba(255, 255, 255, 1),
			600: rgba(255, 255, 255, 1),
			700: rgba(255, 255, 255, 1),
			800: rgba(255, 255, 255, 1),
			900: rgba(255, 255, 255, 1),
			A100: rgba(0, 0, 0, 0.87),
			A200: rgba(0, 0, 0, 0.87),
			A400: rgba(0, 0, 0, 0.87),
			A700: rgba(0, 0, 0, 0.87)
		)
	);

	$green: (
		50: #e8f5e9,
		100: #c8e6c9,
		200: #a5d6a7,
		300: #81c784,
		400: #66bb6a,
		500: #4caf50,
		600: #43a047,
		700: #388e3c,
		800: #2e7d32,
		900: #1b5e20,
		A100: #b9f6ca,
		A200: #69f0ae,
		A400: #00e676,
		A700: #00c853,
		contrast:(
			50: rgba(0, 0, 0, 0.87),
			100: rgba(0, 0, 0, 0.87),
			200: rgba(0, 0, 0, 0.87),
			300: rgba(0, 0, 0, 0.87),
			400: rgba(0, 0, 0, 0.87),
			500: rgba(255, 255, 255, 1),
			600: rgba(255, 255, 255, 1),
			700: rgba(255, 255, 255, 1),
			800: rgba(255, 255, 255, 1),
			900: rgba(255, 255, 255, 1),
			A100: rgba(0, 0, 0, 0.87),
			A200: rgba(0, 0, 0, 0.87),
			A400: rgba(0, 0, 0, 0.87),
			A700: rgba(0, 0, 0, 0.87)
		)
	);

	$light-green: (
		50: #f1f8e9,
		100: #dcedc8,
		200: #c5e1a5,
		300: #aed581,
		400: #9ccc65,
		500: #8bc34a,
		600: #7cb342,
		700: #689f38,
		800: #558b2f,
		900: #33691e,
		A100: #ccff90,
		A200: #b2ff59,
		A400: #76ff03,
		A700: #64dd17,
		contrast:( 
			50: rgba(0, 0, 0, 0.87),
			100: rgba(0, 0, 0, 0.87),
			200: rgba(0, 0, 0, 0.87),
			300: rgba(0, 0, 0, 0.87),
			400: rgba(0, 0, 0, 0.87),
			500: rgba(0, 0, 0, 0.87),
			600: rgba(0, 0, 0, 0.87),
			700: rgba(0, 0, 0, 0.87),
			800: rgba(255, 255, 255, 1),
			900: rgba(255, 255, 255, 1),
			A100: rgba(0, 0, 0, 0.87),
			A200: rgba(0, 0, 0, 0.87),
			A400: rgba(0, 0, 0, 0.87),
			A700: rgba(0, 0, 0, 0.87)
		)
	);

	$lime: (
		50: #f9fbe7,
		100: #f0f4c3,
		200: #e6ee9c,
		300: #dce775,
		400: #d4e157,
		500: #cddc39,
		600: #c0ca33,
		700: #afb42b,
		800: #9e9d24,
		900: #827717,
		A100: #f4ff81,
		A200: #eeff41,
		A400: #c6ff00,
		A700: #aeea00,
		contrast:(
			50: rgba(0, 0, 0, 0.87),
			100: rgba(0, 0, 0, 0.87),
			200: rgba(0, 0, 0, 0.87),
			300: rgba(0, 0, 0, 0.87),
			400: rgba(0, 0, 0, 0.87),
			500: rgba(0, 0, 0, 0.87),
			600: rgba(0, 0, 0, 0.87),
			700: rgba(0, 0, 0, 0.87),
			800: rgba(0, 0, 0, 0.87),
			900: rgba(255, 255, 255, 1),
			A100: rgba(0, 0, 0, 0.87),
			A200: rgba(0, 0, 0, 0.87),
			A400: rgba(0, 0, 0, 0.87),
			A700: rgba(0, 0, 0, 0.87)
		)
	);

	$yellow: (
		50: #fffde7,
		100: #fff9c4,
		200: #fff59d,
		300: #fff176,
		400: #ffee58,
		500: #ffeb3b,
		600: #fdd835,
		700: #fbc02d,
		800: #f9a825,
		900: #f57f17,
		A100: #ffff8d,
		A200: #ffff00,
		A400: #ffea00,
		A700: #ffd600,
		contrast:(
			50: rgba(0, 0, 0, 0.87),
			100: rgba(0, 0, 0, 0.87),
			200: rgba(0, 0, 0, 0.87),
			300: rgba(0, 0, 0, 0.87),
			400: rgba(0, 0, 0, 0.87),
			500: rgba(0, 0, 0, 0.87),
			600: rgba(0, 0, 0, 0.87),
			700: rgba(0, 0, 0, 0.87),
			800: rgba(0, 0, 0, 0.87),
			900: rgba(0, 0, 0, 0.87),
			A100: rgba(0, 0, 0, 0.87),
			A200: rgba(0, 0, 0, 0.87),
			A400: rgba(0, 0, 0, 0.87),
			A700: rgba(0, 0, 0, 0.87)
		)
	);

	$amber: (
		50: #fff8e1,
		100: #ffecb3,
		200: #ffe082,
		300: #ffd54f,
		400: #ffca28,
		500: #ffc107,
		600: #ffb300,
		700: #ffa000,
		800: #ff8f00,
		900: #ff6f00,
		A100: #ffe57f,
		A200: #ffd740,
		A400: #ffc400,
		A700: #ffab00,
		contrast:( 
			50: rgba(0, 0, 0, 0.87),
			100: rgba(0, 0, 0, 0.87),
			200: rgba(0, 0, 0, 0.87),
			300: rgba(0, 0, 0, 0.87),
			400: rgba(0, 0, 0, 0.87),
			500: rgba(0, 0, 0, 0.87),
			600: rgba(0, 0, 0, 0.87),
			700: rgba(0, 0, 0, 0.87),
			800: rgba(0, 0, 0, 0.87),
			900: rgba(0, 0, 0, 0.87),
			A100: rgba(0, 0, 0, 0.87),
			A200: rgba(0, 0, 0, 0.87),
			A400: rgba(0, 0, 0, 0.87),
			A700: rgba(0, 0, 0, 0.87)
		)
	);

	$orange: (
		50: #fff3e0,
		100: #ffe0b2,
		200: #ffcc80,
		300: #ffb74d,
		400: #ffa726,
		500: #ff9800,
		600: #fb8c00,
		700: #f57c00,
		800: #ef6c00,
		900: #e65100,
		A100: #ffd180,
		A200: #ffab40,
		A400: #ff9100,
		A700: #ff6d00,
		contrast:(
			50: rgba(0, 0, 0, 0.87),
			100: rgba(0, 0, 0, 0.87),
			200: rgba(0, 0, 0, 0.87),
			300: rgba(0, 0, 0, 0.87),
			400: rgba(0, 0, 0, 0.87),
			500: rgba(0, 0, 0, 0.87),
			600: rgba(0, 0, 0, 0.87),
			700: rgba(0, 0, 0, 0.87),
			800: rgba(255, 255, 255, 1),
			900: rgba(255, 255, 255, 1),
			A100: rgba(0, 0, 0, 0.87),
			A200: rgba(0, 0, 0, 0.87),
			A400: rgba(0, 0, 0, 0.87),
			A700: rgba(0, 0, 0, 0.87)
		)
	);

	$deep-orange: (
		50: #fbe9e7,
		100: #ffccbc,
		200: #ffab91,
		300: #ff8a65,
		400: #ff7043,
		500: #ff5722,
		600: #f4511e,
		700: #e64a19,
		800: #d84315,
		900: #bf360c,
		A100: #ff9e80,
		A200: #ff6e40,
		A400: #ff3d00,
		A700: #dd2c00,
		contrast:(
			50: rgba(0, 0, 0, 0.87),
			100: rgba(0, 0, 0, 0.87),
			200: rgba(0, 0, 0, 0.87),
			300: rgba(0, 0, 0, 0.87),
			400: rgba(0, 0, 0, 0.87),
			500: rgba(255, 255, 255, 1),
			600: rgba(255, 255, 255, 1),
			700: rgba(255, 255, 255, 1),
			800: rgba(255, 255, 255, 1),
			900: rgba(255, 255, 255, 1),
			A100: rgba(0, 0, 0, 0.87),
			A200: rgba(0, 0, 0, 0.87),
			A400: rgba(255, 255, 255, 1),
			A700: rgba(255, 255, 255, 1)
		)
	);

	$brown: (
		50: #efebe9,
		100: #d7ccc8,
		200: #bcaaa4,
		300: #a1887f,
		400: #8d6e63,
		500: #795548,
		600: #6d4c41,
		700: #5d4037,
		800: #4e342e,
		900: #3e2723,
		A100: #d7ccc8,
		A200: #bcaaa4,
		A400: #8d6e63,
		A700: #5d4037,
		contrast:(
			50: rgba(0, 0, 0, 0.87),
			100: rgba(0, 0, 0, 0.87),
			200: rgba(0, 0, 0, 0.87),
			300: rgba(255, 255, 255, 1),
			400: rgba(255, 255, 255, 1),
			500: rgba(255, 255, 255, 1),
			600: rgba(255, 255, 255, 1),
			700: rgba(255, 255, 255, 1),
			800: rgba(255, 255, 255, 1),
			900: rgba(255, 255, 255, 1),
			A100: rgba(0, 0, 0, 0.87),
			A200: rgba(0, 0, 0, 0.87),
			A400: rgba(255, 255, 255, 1),
			A700: rgba(255, 255, 255, 1)
		)
	);

	$grey: (
		50: #fafafa,
		100: #f5f5f5,
		200: #eeeeee,
		300: #e0e0e0,
		400: #bdbdbd,
		500: #9e9e9e,
		600: #757575,
		700: #616161,
		800: #424242,
		900: #212121,
		A100: #ffffff,
		A200: #eeeeee,
		A400: #bdbdbd,
		A700: #616161,
		contrast:(
			0: rgba(0, 0, 0, 0.87),
			50: rgba(0, 0, 0, 0.87),
			100: rgba(0, 0, 0, 0.87),
			200: rgba(0, 0, 0, 0.87),
			300: rgba(0, 0, 0, 0.87),
			400: rgba(0, 0, 0, 0.87),
			500: rgba(0, 0, 0, 0.87),
			600: rgba(255, 255, 255, 1),
			700: rgba(255, 255, 255, 1),
			800: rgba(255, 255, 255, 1),
			900: rgba(255, 255, 255, 1),
			A100: rgba(0, 0, 0, 0.87),
			A200: rgba(0, 0, 0, 0.87),
			A400: rgba(0, 0, 0, 0.87),
			A700: rgba(255, 255, 255, 1)
		)
	);

	$blue-grey: (
		50: #eceff1,
		100: #cfd8dc,
		200: #b0bec5,
		300: #90a4ae,
		400: #78909c,
		500: #607d8b,
		600: #546e7a,
		700: #455a64,
		800: #37474f,
		900: #263238,
		A100: #cfd8dc,
		A200: #b0bec5,
		A400: #78909c,
		A700: #455a64,
		contrast:(
			50: rgba(0, 0, 0, 0.87),
			100: rgba(0, 0, 0, 0.87),
			200: rgba(0, 0, 0, 0.87),
			300: rgba(0, 0, 0, 0.87),
			400: rgba(255, 255, 255, 1),
			500: rgba(255, 255, 255, 1),
			600: rgba(255, 255, 255, 1),
			700: rgba(255, 255, 255, 1),
			800: rgba(255, 255, 255, 1),
			900: rgba(255, 255, 255, 1),
			A100: rgba(0, 0, 0, 0.87),
			A200: rgba(0, 0, 0, 0.87),
			A400: rgba(255, 255, 255, 1),
			A700: rgba(255, 255, 255, 1)
		)
	);

// -----------------------------------------------------------------------------------------------------
// @ Theming
// -----------------------------------------------------------------------------------------------------
@mixin colors-theme($theme) {

    $is-dark: map.get($theme, is-dark);
    $background: map.get($theme, background);
    $foreground: map.get($theme, foreground);

    // Base colors
    & {
        color: map.get($foreground, text);

        // If it's a light theme, use a darker background
        // color other than the default #fafafa
        @if ($is-dark) {
            background: map.get($background, background);
        } @else {
            // background: #F5F5F5;
            background: #FFFFFF;
        }
    }

    .secondary-text {
        color: map.get($foreground, secondary-text);
    }

    .icon {
        color: map.get($foreground, icon);
    }

    .hint-text {
        color: map.get($foreground, hint-text);
    }

    .disabled-text {
        color: map.get($foreground, disabled-text);
    }

    .fade-text,
    .divider {
        color: map.get($foreground, divider);
    }
}

// -----------------------------------------------------------------------------------------------------
// @ Material colors map
// -----------------------------------------------------------------------------------------------------
$matPalettes: (
    red: $red,
	pink: $pink,
	purple: $purple,
	deep-purple: $deep-purple,
	indigo: $indigo,
	blue: $blue,
	light-blue: $light-blue,
	cyan: $cyan,
	teal: $teal,
	green: $green,
	light-green: $light-green,
	lime: $lime,
	yellow: $yellow,
	amber: $amber,
	orange: $orange,
	deep-orange: $deep-orange,
	brown: $brown,
	grey: $grey,
	blue-grey: $blue-grey
);

// Material color hues list
$matHues: 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, A100, A200, A400, A700;
// -----------------------------------------------------------------------------------------------------
// debug gives info about the variable in the terminal
//@debug $matPalettes; 


// @ Text color levels generator
// -----------------------------------------------------------------------------------------------------
@mixin generate-text-color-levels($classes, $contrast) {

    // If the contrast is dark...
    @if ($contrast == 'dark') {

        // Put down the color classes
        #{$classes} {

            .icon {
                color: rgba(0, 0, 0, 0.54);
            }

            &.secondary-text,
            .secondary-text {
                color: rgba(0, 0, 0, 0.54) !important;
            }

            &.hint-text,
            .hint-text,
            &.disabled-text,
            .disabled-text {
                color: rgba(0, 0, 0, 0.38) !important;
            }

            &.divider,
            .divider {
                color: rgba(0, 0, 0, 0.12) !important;
            }

            .mat-ripple-element {
                background: rgba(0, 0, 0, 0.1);
            }

            .adaptive-border-color {
                border-color: rgba(0, 0, 0, 0.12);
            }
        }

    }
        // If the base text color is white...
    @else {

        // Put down the color classes
        #{$classes} {

            .icon {
                color: rgba(255, 255, 255, 1);
            }

            &.secondary-text,
            .secondary-text {
                color: rgba(255, 255, 255, 0.70) !important;
            }

            &.hint-text,
            .hint-text,
            &.disabled-text,
            .disabled-text {
                color: rgba(255, 255, 255, 0.50) !important;
            }

            &.divider,
            .divider {
                color: rgba(255, 255, 255, 0.12) !important;
            }

            .mat-ripple-element {
                background: rgba(255, 255, 255, 0.1);
            }

            .adaptive-border-color {
                border-color: rgba(255, 255, 255, 0.12);
            }
        }
    }
}

// -----------------------------------------------------------------------------------------------------
// @ Material element colors generator
// -----------------------------------------------------------------------------------------------------
@mixin generate-material-element-colors($classes, $contrast) {

    // If the contrast color is light...
    $fuseForeground: (
        base: white,
        text: white,
        hint-text: rgba(white, 0.5),
        divider: rgba(white, 0.12),
    );

    // If the contrast color is dark...
    @if ($contrast == 'dark') {

        $fuseForeground: (
            base: black,
            hint-text: rgba(black, 0.38),
            divider: rgba(black, 0.12),
        );
    }

    // Put down the color classes
    #{$classes} {

        // Native Input
        input[type="text"] {
            color: map.get($fuseForeground, base);
        }

        // Input
        .mat-form-field-label {
            color: map.get($fuseForeground, hint-text);
        }
        .mat-form-field-underline {
            background-color: map.get($fuseForeground, divider);
        }

        // Select
        .mat-select-trigger,
        .mat-select-arrow {
            color: map.get($fuseForeground, hint-text);
        }
        .mat-select-underline {
            background-color: map.get($fuseForeground, divider);
        }
        .mat-select-disabled .mat-select-value,
        .mat-select-arrow,
        .mat-select-trigger {
            color: map.get($fuseForeground, hint-text);
        }

        // .mat-select-content,
        // .mat-select-panel-done-animating {
        //     //background: map.get($background, card);
        // }
        .mat-select-value {
            color: map.get($fuseForeground, text);
        }
    }
}

// -----------------------------------------------------------------------------------------------------
// @ Color classes generator
// -----------------------------------------------------------------------------------------------------
@mixin generate-color-classes($colorName, $color, $contrastColor, $hue) {

    .#{$colorName}#{$hue}-bg {
        background-color: $color !important;
    }

    .#{$colorName}#{$hue} {
        background-color: $color !important;
        color: $contrastColor !important;

        &[disabled] {
            // background-color: rgba(206, 220, 227, 0.5) !important;
            background-color: rgba($color, 0.25) !important;
            color: rgba(#444, 0.6) !important;
        }
    }

    .#{$colorName}#{$hue}-fg {
        color: $color !important;
    }

    .#{$colorName}#{$hue}-border {
        border-color: $color !important;
    }

    .#{$colorName}#{$hue}-border-top {
        border-top-color: $color !important;
    }

    .#{$colorName}#{$hue}-border-right {
        border-right-color: $color !important;
    }

    .#{$colorName}#{$hue}-border-bottom {
        border-bottom-color: $color !important;
    }

    .#{$colorName}#{$hue}-border-left {
        border-left-color: $color !important;
    }
}

// -----------------------------------------------------------------------------------------------------
// @ Fuse color classes
// -----------------------------------------------------------------------------------------------------
@mixin fuse-color-classes($palettes) {

    // Define contrast lists
    $light-contrasting-classes: ();
    $dark-contrasting-classes: ();

    // Generate the color classes...
    @each $paletteName, $palette in $palettes {

        // Get the contrasts map
        $contrasts: map.get($palette, 'contrast');

        @each $hue in $matHues {

            // Get the color and the contrasting color
            $color: map.get($palette, $hue);
            $contrast: map.get($contrasts, $hue);

            @if ($color != null and $contrast != null) {

                // Generate color classes
                @include generate-color-classes($paletteName, $color, $contrast, '-#{$hue}');

                // If the contrast color is dark
                @if (rgba(black, 1) == rgba($contrast, 1)) {
                    $dark-contrasting-classes: append($dark-contrasting-classes, unquote('.#{$paletteName}-#{$hue}'), 'comma');
                }
                    // if the contrast color is light
                @else {
                    $light-contrasting-classes: append($light-contrasting-classes, unquote('.#{$paletteName}-#{$hue}'), 'comma');
                }

                // Run the generator one more time for default values (500)
                // if we are not working with primary, accent or warn palettes
                @if ($hue == 500 and $paletteName != 'primary' and $paletteName != 'accent' and $paletteName != 'warn') {

                    // Generate color classes
                    @include generate-color-classes($paletteName, $color, $contrast, '');

                    // Add color to the correct list depending on the contrasting color

                    // If the contrast color is dark
                    @if (rgba(black, 1) == rgba($contrast, 1)) {
                        $dark-contrasting-classes: append($dark-contrasting-classes, unquote('.#{$paletteName}'), 'comma');
                    }
                        // if the contrast color is light
                    @else {
                        $light-contrasting-classes: append($light-contrasting-classes, unquote('.#{$paletteName}'), 'comma');
                    }
                }
            }
        }

        // Run the generator again for the selected default hue values for
        // primary, accent and warn palettes
        //
        // We are doing this because the default hue value can be changed
        // by the user when the Material theme being generated.
        @if ($paletteName == 'primary' or $paletteName == 'accent' or $paletteName == 'warn') {

            // Get the color and the contrasting color for the selected
            // default hue
            $color: map.get($palette, 'default');
            $contrast: map.get($palette, 'default-contrast');

            // Generate color classes
            @include generate-color-classes($paletteName, $color, $contrast, '');

            // Add color to the correct list depending on the contrasting color

            // If the contrast color is dark
            @if (rgba(black, 1) == rgba($contrast, 1)) {
                $dark-contrasting-classes: append($dark-contrasting-classes, unquote('.#{$paletteName}'), 'comma');
            }
                // if the contrast color is light
            @else {
                $light-contrasting-classes: append($light-contrasting-classes, unquote('.#{$paletteName}'), 'comma');
            }
        }
    }

    // Generate contrasting colors
    @include generate-text-color-levels($dark-contrasting-classes, 'dark');
    @include generate-text-color-levels($light-contrasting-classes, 'light');
    @include generate-material-element-colors($dark-contrasting-classes, 'dark');
    @include generate-material-element-colors($light-contrasting-classes, 'light');
}

// -----------------------------------------------------------------------------------------------------
// @ Generate Fuse color classes for default Material palettes
// -----------------------------------------------------------------------------------------------------
@include fuse-color-classes($matPalettes);
