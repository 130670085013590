@keyframes bounce {

	from,
	20%,
	53%,
	to {
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		transform: translate3d(0, 0, 0);
	}

	40%,
	43% {
		animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		transform: translate3d(0, -30px, 0) scaleY(1.1);
	}

	70% {
		animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		transform: translate3d(0, -15px, 0) scaleY(1.05);
	}

	80% {
		transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		transform: translate3d(0, 0, 0) scaleY(0.95);
	}

	90% {
		transform: translate3d(0, -4px, 0) scaleY(1.02);
	}
}

@keyframes flash {

	from,
	50%,
	to {
		opacity: 1;
	}

	25%,
	75% {
		opacity: 0;
	}
}

@keyframes heartBeat {
	0% {
		transform: scale(1);
	}

	14% {
		transform: scale(1.3);
	}

	28% {
		transform: scale(1);
	}

	42% {
		transform: scale(1.3);
	}

	70% {
		transform: scale(1);
	}
}

@keyframes pulse {
	from {
		transform: scale3d(1, 1, 1);
	}

	50% {
		transform: scale3d(1.25, 1.25, 1.25);
	}

	to {
		transform: scale3d(1, 1, 1);
	}
}

@keyframes tada {
	from {
		transform: scale3d(1, 1, 1);
	}

	10%,
	20% {
		transform: scale3d(0.8, 0.8, 0.8) rotate3d(0, 0, 1, -3deg);
	}

	30%,
	50%,
	70%,
	90% {
		transform: scale3d(1.2, 1.2, 1.2) rotate3d(0, 0, 1, 3deg);
	}

	40%,
	60%,
	80% {
		transform: scale3d(1.2, 1.2, 1.2) rotate3d(0, 0, 1, -3deg);
	}

	to {
		transform: scale3d(1, 1, 1);
	}
}