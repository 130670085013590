@use 'sass:map';
@use '@angular/material' as mat;

@use './pages/chat-panel/chat-panel.theme' as chatPanelTheme;
@use './layout/components/toolbar/toolbar.theme' as toolbarTheme;
@use './pages/auth/register/register.theme' as registerTheme;
@use './pages/rainout/event-form/event-form.theme' as eventFormTheme;
@use './pages/hq/sport-setup/sport-setup.theme' as sportSetupTheme;
@use './pages/player/player.theme' as playerTheme;
@use './pages/events/pubEvents/public-event-detail/public-event-detail.theme' as publicEventDetailTheme;
@use './pages/clubs/club-detail/club-detail.theme' as clubDetailTheme;
@use '../@tinc/components/rp-cms-editor/rp-cms-editor.theme' as rpCmsEditorTheme;
@use './pages/help/video-library/video-library.theme' as videoLibraryTheme;

@include mat.core();

@import '../styles/variables';



//When converting to @use, you need to prefix mixins, functions, and variables from the used module with the module's namespace.

/* Set the base colors for light themes */
$light-base: (
    foreground: (
        base: #000000,
        divider: #e2e8f0,
        /* slate.200 */ dividers: #e2e8f0,
        /* slate.200 */ disabled: #94a3b8,
        /* slate.400 */ disabled-button: #94a3b8,
        /* slate.400 */ disabled-text: #94a3b8,
        /* slate.400 */ elevation: #000000,
        hint-text: #94a3b8,
        /* slate.400 */ secondary-text: #64748b,
        /* slate.500 */ icon: #64748b,
        /* slate.500 */ icons: #64748b,
        /* slate.500 */ mat-icon: #64748b,
        /* slate.500 */ text: #1e293b,
        /* slate.800 */ slider-min: #1e293b,
        /* slate.800 */ slider-off: #cbd5e1,
        /* slate.300 */ slider-off-active: #94a3b8 /* slate.400 */,
    ),
    background: (
        status-bar: #cbd5e1,
        /* slate.300 */ app-bar: #ffffff,
        background: #f1f5f9,
        /* slate.100 */ hover: rgba(148, 163, 184, 0.12),
        /* slate.400 + opacity */ card: #ffffff,
        dialog: #ffffff,
        disabled-button: rgba(148, 163, 184, 0.38),
        /* slate.400 + opacity */ raised-button: #ffffff,
        focused-button: #64748b,
        /* slate.500 */ selected-button: #e2e8f0,
        /* slate.200 */ selected-disabled-button: #e2e8f0,
        /* slate.200 */ disabled-button-toggle: #cbd5e1,
        /* slate.300 */ unselected-chip: #e2e8f0,
        /* slate.200 */ disabled-list-option: #cbd5e1,
        /* slate.300 */ tooltip: #1e293b /* slate.800 */,
    ),
);

/* Set the base colors for dark themes */
$dark-base: (
    foreground: (
        base: #ffffff,
        divider: rgba(241, 245, 249, 0.12),
        /* slate.100 + opacity */ dividers: rgba(241, 245, 249, 0.12),
        /* slate.100 + opacity */ disabled: #475569,
        /* slate.600 */ disabled-button: #1e293b,
        /* slate.800 */ disabled-text: #475569,
        /* slate.600 */ elevation: #000000,
        hint-text: #64748b,
        /* slate.500 */ secondary-text: #94a3b8,
        /* slate.400 */ icon: #f1f5f9,
        /* slate.100 */ icons: #f1f5f9,
        /* slate.100 */ mat-icon: #94a3b8,
        /* slate.400 */ text: #ffffff,
        slider-min: #ffffff,
        slider-off: #64748b,
        /* slate.500 */ slider-off-active: #94a3b8 /* slate.400 */,
    ),
    background: (
        status-bar: #0f172a,
        /* slate.900 */ app-bar: #0f172a,
        /* slate.900 */ background: #0f172a,
        /* slate.900 */ hover: rgba(255, 255, 255, 0.05),
        card: #1e293b,
        /* slate.800 */ dialog: #1e293b,
        /* slate.800 */ disabled-button: rgba(15, 23, 42, 0.38),
        /* slate.900 + opacity */ raised-button: #0f172a,
        /* slate.900 */ focused-button: #e2e8f0,
        /* slate.200 */ selected-button: rgba(255, 255, 255, 0.05),
        selected-disabled-button: #1e293b,
        /* slate.800 */ disabled-button-toggle: #0f172a,
        /* slate.900 */ unselected-chip: #475569,
        /* slate.600 */ disabled-list-option: #e2e8f0,
        /* slate.200 */ tooltip: #64748b /* slate.500 */,
    ),
);

// create palette http://mcg.mbitson.com/#!?mcgpalette0=%23003399
// https://www.materialpalette.com/
// https://material.angularjs.org/1.1.0/demo/colors

// -----------------------------------------------------------------------------------------------------
// @ Custom color maps for Fuse
// -----------------------------------------------------------------------------------------------------
$fuse-white: (
	500: white,
	contrast: (
		500: rgba(black, 0.87)
	)
);

$rp-blue: (
	50: #e4e7ed,
	100: #bac3d2,
	200: #8d9bb4,
	300: #5f7295,
	400: #3c547f,
	500: #1a3668,
	600: #173060,
	700: #132955,
	800: #0f224b,
	900: #08163a,
	A100: #7292ff,
	A200: #3f6aff,
	A400: #0c43ff,
	A700: #0036f1,
	contrast: (
		50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: #fff,
        400: #fff,
        500: #fff,
        600: #fff,
        700: #fff,
        800: #fff,
        900: #fff,
        A100: rgba(black, 0.87),
        A200: #fff,
        A400: #fff,
        A700: #fff,
	)
);

$rp-red: (
	50: #ffebee,
	100: #ffcdd2,
	200: #ef9a9a,
	300: #e57373,
	400: #ef5350,
	500: #f44336,
	600: #e53935,
	700: #d32f2f,
	800: #c62828,
	900: #b71c1c,
	A100: #ff8a80,
	A200: #ff5252,
	A400: #ff1744,
	A700: #d50000,
	contrast: (
		50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: #fff,
        400: #fff,
        500: #fff,
        600: #fff,
        700: #fff,
        800: #fff,
        900: #fff,
        A100: rgba(black, 0.87),
        A200: #fff,
        A400: #fff,
        A700: #fff,
	)
);

$rp-gray: (
	50: #f7f7f7,
	100: #ebebeb,
	200: #deddde,
	300: #d1cfd0,
	400: #c7c5c6,
	500: #bdbbbc,
	600: #b7b5b6,
	700: #aeacad,
	800: #a6a4a5,
	900: #989697,
	A100: #cdd1d4,
	A200: #ffffff,
	A400: #ffd2e1,
	A700: #6C6C70,
	contrast: (
		50: rgba(black, 0.87),
		100: rgba(black, 0.87),
		200: rgba(black, 0.87),
		300: #fff,
		400: #fff,
		500: #fff,
		600: #fff,
		700: #fff,
		800: #fff,
		900: #fff,
		A100: rgba(black, 0.87),
		A200: #fff,
		A400: #fff,
		A700: #fff
	)
);

$fuse-black: (
    50: #e0e0e0,
	100: #b3b3b3,
	200: #808080,
	300: #4d4d4d,
	400: #262626,
	500: #000000,
	600: #000000,
	700: #000000,
	800: #000000,
	900: #000000,
	A100: #a6a6a6,
	A200: #8c8c8c,
	A400: #737373,
	A700: #666666,
	contrast: (
		50: rgba(black, 0.87),
		100: rgba(black, 0.87),
		200: rgba(black, 0.87),
		300: #fff,
		400: #fff,
		500: #fff,
		600: #fff,
		700: #fff,
		800: #fff,
		900: #fff,
		A100: rgba(black, 0.87),
		A200: rgba(black, 0.87),
		A400: #fff,
		A700: #fff,
	)
);

$fuse-navy: (
    50: #ECECEE,
    100: #C5C6CB,
    200: #9EA1A9,
    300: #7D818C,
    400: #5C616F,
    500: #3C4252,
    600: #353A48,
    700: #2D323E,
    800: #262933,
    900: #1E2129,
    A100: #C5C6CB,
    A200: #9EA1A9,
    A400: #5C616F,
    //A700: #2D323E,
    A700: #2132a4,
    contrast: (
        50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: #fff,
        400: #fff,
        500: #fff,
        600: #fff,
        700: #fff,
        800: #fff,
        900: #fff,
        A100: rgba(black, 0.87),
        A200: #fff,
        A400: #fff,
        A700: #fff,
    )
);

$fuse-orange: (A800: #ff6100,
    contrast: (500: #fff,
));

$fuse-gold:(
  50: #f9f7ed,
  100: #f0ebd1,
  200: #e6ddb2,
  300: #dccf93,
  400: #d5c57c,
  500: #cdbb65,
  600: #c8b55d,
  700: #c1ac53,
  800: #baa449,
  900: #ae9637,
  A100: #fffefa,
  A200: #fff3c7,
  A400: #ffe894,
  A700: #ffe27a,
  contrast: (
        50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: rgba(black, 0.87),
        400: rgba(black, 0.87),
        500: rgba(black, 0.87),
        600: rgba(black, 0.87),
        700: rgba(black, 0.87),
        800: rgba(black, 0.87),
        900: rgba(black, 0.87),
        A100: rgba(black, 0.87),
        A200: rgba(black, 0.87),
        A400: rgba(black, 0.87),
        A700: rgba(black, 0.87),
    )
);

$dark-orange:(
    50: #ffece0,
    100: #ffd0b3,
    200: #ffb080,
    300: #ff904d,
    400: #ff7926,
    500: #ff6100,
    600: #ff5900,
    700: #ff4f00,
    800: #ff4500,
    900: #ff3300,
    A100: #ffffff,
    A200: #fff4f2,
    A400: #ffc8bf,
    A700: #ffb2a6,
    contrast: (
        50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: #fff,
        400: #fff,
        500: #fff,
        600: #fff,
        700: #fff,
        800: #fff,
        900: #fff,
        A100: rgba(black, 0.87),
        A200: #fff,
        A400: #fff,
        A700: #fff,
    )
);

$fuse-dark-grey:(
    50: #ededee,
    100: #d3d3d4,
    200: #b6b6b8,
    300: #98989b,
    400: #828285,
    500: #6c6c70,
    600: #646468,
    700: #59595d,
    800: #4f4f53,
    900: #3d3d41,
    A100: #a4a4f7,
    A200: #7575f3,
    A400: #3636ff,
    A700: #1d1dff,
    contrast: (
        50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: #fff,
        400: #fff,
        500: #fff,
        600: #fff,
        700: #fff,
        800: #fff,
        900: #fff,
        A100: rgba(black, 0.87),
        A200: #fff,
        A400: #fff,
        A700: #fff,
    )
);

$fuse-blue:(
	50 : #e1edf6,
    100 : #b3d2e8,
    200 : #80b4d9,
    300 : #4d96ca,
    400 : #2780be,
    500 : #0169b3,
    600 : #0161ac,
    700 : #0156a3,
    800 : #014c9a,
    900 : #003b8b,
    A100 : #b7d0ff,
    A200 : #84aeff,
    A400 : #518dff,
    A700 : #377cff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
    )
);

// Generate Fuse color classes for custom palettes
$custom_palettes: (
	rp-blue: $rp-blue,
	rp-red: $rp-red,
	rp-gray: $rp-gray,
	fuse-white: $fuse-white,
	fuse-black: $fuse-black,
	fuse-navy: $fuse-navy,
	fuse-orange: $fuse-orange,
	fuse-gold: $fuse-gold,
	dark-orange: $dark-orange,
	fuse-dark-grey: $fuse-dark-grey,
	fuse-blue:$fuse-blue,
	custom-primary: $custom-primary,
	custom-accent: $custom-accent,
	custom-warn: $custom-warn
);


@include fuse-color-classes($custom_palettes);


// -----------------------------------------------------------------------------------------------------
// @ Component theming
// -----------------------------------------------------------------------------------------------------

// Import app and page component themes here to enable theming for them
// @import "./layout/components/chat-panel/chat-panel.theme.scss";
// @import "./layout/components/toolbar/toolbar.theme.scss";
// @import "./pages/auth/register/register.theme.scss";
// @import "./pages/rainout/event-form/event-form.theme.scss";
// @import "./pages/hq/sport-setup/sport-setup.theme.scss";
// @import "./pages/player/player.theme.scss";
// @import "./pages/events/pubEvents/public-event-detail/public-event-detail.theme.scss";
// @import "./pages/clubs/club-detail/club-detail.theme.scss";
// @import "../@tinc/components/rp-cms-editor/rp-cms-editor.theme.scss";
// @import "./pages/help/video-library/video-library.theme.scss";


// Define a mixin for easier access
@mixin components-theme($theme) {

    // Layout components
    @include chatPanelTheme.chat-panel-theme($theme);
    @include toolbarTheme.toolbar-theme($theme);

    //Pages
    @include registerTheme.register-theme($theme);
    @include clubDetailTheme.club-detail-theme($theme);
    @include videoLibraryTheme.video-library-theme($theme);
     // @include profile-theme($theme);
	@include playerTheme.player-theme($theme);
    @include publicEventDetailTheme.public-event-details-theme($theme);
    @include rpCmsEditorTheme.rp-cms-editor-theme($theme);
    @include eventFormTheme.event-form-theme($theme);
}

// -----------------------------------------------------------------------------------------------------
// @ Typography
// -----------------------------------------------------------------------------------------------------

// Angular Material typography
// full list of supported entries of angular 15
// $font-family,  $headline-1,  $headline-2,
// $headline-3,  $headline-4,  $headline-5,
// $headline-6,  $subtitle-1,  $subtitle-2,
// $body-1, $body-2,  $caption,  $button,  $overline,

$typography: mat.m2-define-typography-config(
    $font-family: 'Muli, Helvetica Neue, Arial, sans-serif, Roboto Mono, Titillium Web',
	$body-1: mat.m2-define-typography-level(14px, 16px, 600),
    $body-2: mat.m2-define-typography-level(14px, 16px, 600),
    $button: mat.m2-define-typography-level(14px, 14px, 600),
	$subtitle-1: mat.m2-define-typography-level(14px, 16px, 600),
);

// -----------------------------------------------------------------------------------------------------
// @ Define the default theme
// Primary - Main colors most widely used across all screens and components.
// Accent - Also known as the secondary color. Used for floating action buttons,Selection controls, like sliders and switches
//          Highlighting selected text,Progress bars, Links and headlines, and interactive elements.
// Warn - Colors to convey error state.
// Foreground - Used for text and icons.
// Background - Colors used for element backgrounds.
// -----------------------------------------------------------------------------------------------------

// -----------------------------------------------------------------------------------------------------
// @ Define the default theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$default-primary-palette: mat.m2-define-palette($custom-primary);
$default-accent-palette: mat.m2-define-palette($custom-accent);
$default-warn-palette: mat.m2-define-palette($custom-warn);

// Create the Material theme object
$theme: mat.m2-define-light-theme((
	color: (
		primary: $default-primary-palette,
		accent: $default-accent-palette,
		warn: $default-warn-palette
	),
	typography: $typography,
	density: 0
));

// $background: map.get($theme, background);
// $background-color: map-get($background, background);
// @debug $background-color;
// $theme: map.merge($theme, $background);
// @debug $background;

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-default {

	@include mat.button-typography($typography);

    // Create an Angular Material theme from the $theme map
    //@include angular-material-theme($theme);

	@include mat.all-component-themes($theme);
	@include mat.typography-hierarchy($theme);
	// @include mat.all-component-colors($theme); 


    // Apply the theme to the Fuse Core
    @include fuse-core-theme($theme);

    // Apply the theme to the user components
    @include components-theme($theme);

    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
        primary: $default-primary-palette,
        accent: $default-accent-palette,
        warn: $default-warn-palette
    );

    @include fuse-color-classes($palettes);
}
