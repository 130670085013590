@mixin public-event-details-theme($theme) {
	// $background: map-get($theme, background);
	// $foreground: map-get($theme, foreground);
	$primary: map-get($theme, primary);
	$accent: map-get($theme, accent);
	// $is-dark: map-get($theme, is-dark);

	publicEvent-details {

		.games-slider li.selected a,
		.games-slider a:hover {
			background: map-get($accent, default);
			color: #fff !important;
			box-shadow: 0 2px map-get($accent, default) inset, 0 -2px map-get($accent, default) inset;
		}

		.filterDay {
			color: map-get($accent, default) !important;
		}
		.game-slider-calendar-day.today {
			color: map-get($primary, default) !important;
		}
	}
}