@use 'sass:map';
@use '@angular/material' as mat;
@include mat.core();

@mixin fuse-shortcuts-theme($theme) {

    $background: map-get($theme, background);

    #fuse-shortcuts {

        &.show-mobile-panel {
            background-color: map-get($background, background);
        }
    }
}