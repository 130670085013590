@mixin toolbar-theme($theme) {
	$primary: map-get($theme, custom-primary);
	$accent: map-get($theme, custom-accent);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $iconColor: map-get($theme, custom-warn);
    // $icon_colors: (
    //     primary: #2D323E,
    //     secondary: #f33737
    // );

    toolbar {
        tinc-icon {
			//color: map-get($icon_colors, primary);
			color:$iconColor;
		}
        .toolbar-separator {
            background: map-get($foreground, divider);
        }

		// .username{
		// 	//color: map-get($foreground, text);
		// 	color: map.get($primary, default-contrast);
		// }
    }
}