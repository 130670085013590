@use "sass:map";

// Fix: "Remove the cdk-global-scrollblock effects from the HTML"
// Fuse already blocks the body scroll so it doesn't need this feature. We are disabling it
// because on Windows, it causes a scrollbar to show up.

html {
  // OVERRIDING THE MATERIAL DEFAULTS, GOD COMPLEXED BASTARDS
  --mdc-typography-body2-font-weight: 100;

  &.cdk-global-scrollblock {
    position: relative !important;
    overflow: hidden !important;
  }
}

body.theme-blue-gray-dark .mat-mdc-table {
  background: #c9c9c9 !important;
}

body.theme-pink-dark .mat-mdc-table {
  background: #c9c9c9 !important;
}

// Fix: "Inconsistent font sizes across elements"
.mat-mdc-checkbox {
  font-size: 16px;
}

.mat-mdc-radio-button {
  font-size: 16px;
}

// mat-icon {
//   font-size: 24px !important;
//   height: 24px !important;
//   width: 24px !important;
//   line-height: 1;

//   @media screen and (max-width: 599px) {
// 	min-height: 48px !important;
// 	min-width: 48px !important;
//   }
// }

.material-icons {
  @media screen and (max-width: 599px) {
    min-height: 48px !important;
    min-width: 48px !important;
    display: flex;
    align-items: center;
    padding: 12px;
  }
}

// Fix: Stepper icons are broken due to Fuse's icon helpers
.mat-step-icon .mat-icon {
  font-size: 16px !important;
  height: 16px !important;
  width: 16px !important;
}
mat-horizontal-stepper,
mat-vertical-stepper {
  mat-step-header {
    mat-icon {
      height: 16px !important;
      width: 16px !important;
      min-width: 0 !important;
      min-height: 0 !important;
      color: inherit !important;
    }
  }
}

mat-vertical-stepper {
  padding: 16px 0;
}

/* -------------------------------------------------------------------------- */
/*  @ Font smoothing
/* -------------------------------------------------------------------------- */
*[class*="mat-"],
*[class*="mat-mdc-"] {
  -webkit-font-smoothing: auto !important;
  -moz-osx-font-smoothing: auto !important;

  * {
    -webkit-font-smoothing: auto !important;
    -moz-osx-font-smoothing: auto !important;
  }
}

/* -------------------------------------------------------------------------- */
/*  @ Accordion
/* -------------------------------------------------------------------------- */
@media screen and (max-width: 599px) {
  .mat-accordion {
    .mat-expansion-panel-body {
      padding: 0 8px 16px !important;
    }

    .mat-expansion-panel-header {
      min-height: 48px;
      height: auto !important;
    }
  }
}

// .mat-expansion-panel {
//   --mat-expansion-container-shape: 12px;
// }

// .mat-accordion {
//   .mat-expansion-panel {
//     margin-bottom: 1px;
//     transition: box-shadow 225ms cubic-bezier(0.4, 0.0, 0.2, 1);

//     // &:last-child {
//     //   margin-bottom: 0;
//     // }

//     .mat-expansion-panel-header {
//       font-size: 14px;
// 	  font-weight: 500;
// 	  line-height: 1.5rem;
// 	  letter-spacing: 0.00938rem;
//       height: 48px;

//       &[aria-disabled=true] {

//         .mat-expansion-panel-header-description {
//           margin-right: 28px;
//         }
//       }

//       .mat-expansion-indicator {
//         display: inline-flex;
//         align-items: center;
//         justify-content: center;
//         width: 12px;
//         height: 12px;

//         /* Do not override the border color of the expansion panel indicator */
//         &:after {
//           border-color: currentColor !important;
//         }
//       }
//     }

//   }
// }

// .mat-expansion-panel-spacing {
//   margin: 16px 0;

//   .mat-accordion > &:first-child,
//   .mat-accordion > *:first-child:not(.mat-expansion-panel) & {
//     margin-top: 0;
//   }

//   .mat-accordion > &:last-child,
//   .mat-accordion > *:last-child:not(.mat-expansion-panel) & {
//     margin-bottom: 0;
//   }
// }

/* -------------------------------------------------------------------------- */
/*  @ Buttons
/* -------------------------------------------------------------------------- */
.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button,
.mat-mdc-icon-button,
.mat-mdc-fab,
.mat-mdc-mini-fab {
  height: 36px;
  // min-height: 36px;
  // max-height: 36px;
  line-height: 1 !important;

  /* Large button */
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  &.fuse-mat-button-large {
    height: 48px;
    min-height: 48px;
    max-height: 48px;
  }

  /* Lower the icon opacity on disabled buttons */
  &[disabled="true"] {
    .mat-icon {
      opacity: 0.38 !important;
    }
  }
}

.mat-mdc-button__ripple {
  display: none;
}

/* Icon buttons */
.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px !important;
  width: 40px;
  height: 40px;
}

.mat-mdc-icon-button {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  //width: 40px !important;
  padding: 0 !important;

  svg,
  img {
    height: auto !important;
  }
}

/* FAB buttons */
.mat-mdc-fab {
  max-height: 56px;
  border-radius: 16px !important;

  &:not(.mdc-fab--extended) .mdc-fab__ripple {
    border-radius: 16px !important;
  }
}

/* Mini FAB buttons */
.mat-mdc-mini-fab {
  border-radius: 12px !important;

  &:not(.mdc-fab--extended) .mdc-fab__ripple {
    border-radius: 12px !important;
  }
}

/*Buton Base */
// .mat-mdc-icon-button.mat-mdc-button-base {
//     width: 30px !important;
//     height: 30px !important;
//     line-height: 30px !important;
// }

/* Rounded design */
.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {
  min-width: 74px !important;
  padding: 0 8px 0 8px !important;
  //border-radius: 9999px !important;
  border-radius: 5px !important;
}

/* Fix the alignment of icons when used within buttons */
.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {
  & > .mat-icon {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

/* Adjust the color of mat-progress-spinner when used within buttons */
.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button,
.mat-mdc-icon-button,
.mat-mdc-fab,
.mat-mdc-mini-fab {
  .mat-mdc-progress-spinner {
    .mdc-circular-progress__indeterminate-container {
      circle {
        stroke: currentColor !important;
        animation-duration: 6000ms;
      }
    }
  }
}

/* Adjust the focus, ripple and icon colors of colored background buttons */
.mat-mdc-raised-button,
.mat-mdc-unelevated-button,
.mat-mdc-fab,
.mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: theme(
    "colors.gray[400]"
  ) !important;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1) !important;

  .dark & {
    --mat-mdc-button-persistent-ripple-color: theme("colors.black") !important;
    --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1) !important;
  }

  .mat-icon {
    color: currentColor !important;
  }
}

@media screen and (max-width: 599px) {
  .mat-mdc-icon-button {
    min-width: 48px;
    min-height: 48px;
  }
}

/* Don't wrap the button label text */
// .mdc-button {

//     .mdc-button__label {
//         white-space: nowrap;
//     }
// }

/* Color the icons of transparent background buttons */
// .mat-mdc-button,
// .mat-mdc-icon-button,
// .mat-mdc-outlined-button {

//     &:not([disabled=true]) {

//         /* Apply primary color */
//         &.mat-primary {

//             .mat-icon {
//                 @apply text-primary #{'!important'};
//             }
//         }

//         /* Apply accent color */
//         &.mat-accent {

//             .mat-icon {
//                 @apply text-accent #{'!important'};
//             }
//         }

//         /* Apply warn color */
//         &.mat-warn {

//             .mat-icon {
//                 @apply text-warn #{'!important'};
//             }
//         }
//     }
// }

/* Adjust the border color of outlined buttons */
// .mat-mdc-outlined-button {

//     /* Not disabled */
//     &:not([disabled=true]) {
//         @apply border-gray-300 dark:border-gray-500 #{'!important'};
//     }

//     /* Disabled */
//     &[disabled=true] {
//         @apply border-gray-300/70 dark:border-gray-600 #{'!important'};
//     }
// }

/* -------------------------------------------------------------------------- */
/*  @Form Field
/* -------------------------------------------------------------------------- */
.mat-mdc-form-field {
  margin-top: 8px;
}

//as google play store for mobile considers the labels as a low contrast
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.87) !important;
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.87) !important;
}
.mat-form-field-label {
  color: rgba(
    0,
    0,
    0,
    0.87
  ) !important; // Adjust this value to increase contrast
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.87) !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--invalid
  .mdc-floating-label {
  color: rgba(0, 0, 0, 0.87) !important;
  --mdc-outlined-text-field-error-label-text-color: rgba(
    0,
    0,
    0,
    0.87
  ) !important;
}

// .mdc-text-field--filled:not(.mdc-text-field--disabled) {
//   --mdc-filled-text-field-container-color: #e0e2ec !important;
// }

// .mdc-text-field--filled:not(.mdc-text-field--disabled) {
//   background: transparent !important;
// }

/* -------------------------------------------------------------------------- */
/*  @ CDK OVERLAY for DIALOG
/* -------------------------------------------------------------------------- */
.cdk-overlay-pane.mat-mdc-dialog-panel {
  --mat-dialog-container-small-max-width: 100% !important;
}

/* -------------------------------------------------------------------------- */
/*  @ Button Toggle
/* -------------------------------------------------------------------------- */
.mat-button-toggle-group {
  //border: none !important;
  //@apply space-x-1;

  &.mat-button-toggle-group-appearance-standard {
    .mat-button-toggle + .mat-button-toggle {
      background-clip: padding-box;
    }

    .mat-button-toggle-appearance-standard.mat-button-toggle-checked {
      color: #131c2b !important;
      background-color: #dae2f9 !important;
      font-weight: bold !important;
    }
  }

  .mat-button-toggle {
    border-radius: 0px;
    overflow: hidden;
    //border: none !important;
    font-weight: 500;
  }
}

/* -------------------------------------------------------------------------- */
/*  @ Checkbox
/* -------------------------------------------------------------------------- */
.mat-mdc-checkbox {
  display: inline-flex !important;

  //   .mdc-form-field {
  //     padding-right: 12px;
  //   }
}

.mdc-checkbox__native-control {
  opacity: 0 !important;
}

//Google play store for mobile considers the labels as a low contrast
.mat-checkbox-label {
  color: rgba(
    0,
    0,
    0,
    0.87
  ) !important; // Ensure high contrast for checkbox labels
}

/* -------------------------------------------------------------------------- */
/*  @ Chip
/* -------------------------------------------------------------------------- */

.mdc-evolution-chip-set .mat-mdc-standard-chip {
  .mdc-evolution-chip__cell--primary,
  .mdc-evolution-chip__action--primary,
  .mat-mdc-chip-action-label {
    overflow: hidden;
    justify-content: center;
  }
}

.mat-mdc-standard-chip {
  //border-radius: 8px !important;
  --mdc-chip-outline-color: #74777f !important;
  --mdc-chip-outline-width: 1px !important;
  --mdc-chip-container-shape-radius: 8px !important;
  --mdc-chip-elevated-container-color: rgba(0, 0, 0, 0) !important;
  //background-color: rgba(0, 0, 0, 0) !important;
  //border: 1px solid #cecece;
  min-width: 85px !important;
  @media screen and (max-width: 599px) {
    --mdc-chip-container-height: 35px !important;
  }
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled)
  .mdc-evolution-chip__text-label {
  color: inherit !important;
}

.mat-mdc-chip-action-label {
  display: flex;
  align-items: center;
}

/* -------------------------------------------------------------------------- */
/*  @ Dialog
/* -------------------------------------------------------------------------- */
// Fix: "Smooth scrolling for iOS"
// .mat-mdc-dialog-content {
//     max-height: unset !important;
// }

//.mat-dialog-content { max-height: unset; }

.mat-mdc-dialog-container {
  -webkit-overflow-scrolling: touch;
}

.mat-mdc-dialog-container {
  .mdc-dialog__surface {
    border-radius: 16px !important;
    padding: 24px;
    overflow-x: hidden;
  }
}

@media screen and (max-width: 959px) {
  .mat-mdc-dialog-container {
    .mdc-dialog__surface {
      padding: 12px !important;
    }
  }
}

.mdc-dialog .mdc-dialog__content {
  padding: 4px;
  overflow-x: hidden;
  color: rgba(0, 0, 0) !important;

  @media screen and (max-width: 959px) {
    padding: 20px 10px 20px 10px !important;
  }
}

.mat-mdc-dialog-container .mdc-dialog__content {
  color: rgba(0, 0, 0) !important;
}

/* -------------------------------------------------------------------------- */
/*  @ Drawer
/* -------------------------------------------------------------------------- */
.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

/* -------------------------------------------------------------------------- */
/*  @ Datepicker
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*  @ Icon
/* -------------------------------------------------------------------------- */
.mat-icon {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
  font-size: 24px;
  line-height: 24px;
}

/* -------------------------------------------------------------------------- */
/*  @ Menu
/* -------------------------------------------------------------------------- */
mat-mdc-menu-item:not([disabled]):hover {
  --mat-menu-item-hover-state-layer-color: unset !important;
  //background-color: unset !important;
}

button.mat-menu-item:hover {
  background-color: inherit !important;
}

.mat-mdc-menu-panel {
  min-width: 144px !important;

  .mat-mdc-menu-content {
    .mat-mdc-menu-item {
      display: flex;
      align-items: center;

      > span {
        display: inline-flex;
        align-items: center;
      }

      &.mat-mdc-menu-item-submenu-trigger {
        padding-right: 40px;
      }

      .mat-icon {
        margin-right: 12px;
      }

      .mat-icon-no-color {
        --tw-text-opacity: 1;
        color: rgba(var(--fuse-mat-icon-rgb), var(--tw-text-opacity));
      }
    }

    /* Divider within mat-menu */
    mat-divider {
      margin: 8px 0;
    }
  }
}

/* -------------------------------------------------------------------------- */
/*  @ MAT-CARD
/* -------------------------------------------------------------------------- */
.mat-mdc-card-header-text {
  width: 100% !important;
}

/* -------------------------------------------------------------------------- */
/*  @ Slide Toggle
/* -------------------------------------------------------------------------- */
.mat-mdc-slide-toggle {
  margin: 8px 0;
}

.mat-mdc-slide-toggle .mdc-form-field {
  color: inherit !important;
}

/* -------------------------------------------------------------------------- */
/*  @ Stepper
/* -------------------------------------------------------------------------- */
.mat-step-icon {
  /* Do not override the mat-icon color */
  .mat-icon {
    color: currentColor !important;
  }
}

.mat-step-label,
.mat-step-label-selected {
  font-weight: 500 !important;
}

@media screen and (max-width: 599px) {
  .mat-vertical-stepper-header {
    padding: 12px !important;
  }

  .mat-vertical-content-container {
    margin-left: 24px !important;
  }
}

/* -------------------------------------------------------------------------- */
/*  @ Table
/* -------------------------------------------------------------------------- */
// .mat-mdc-table {
//   .mdc-data-table__cell, .mdc-data-table__header-cell {
//     padding: 0 8px;
//   }

// }
@media screen and (max-width: 599px) {
  .mat-sort-header-container,
  .mat-mdc-header-cell,
  .mat-sort-header-content {
    min-width: 48px;
    min-height: 48px;
    font-size: 16px;
  }

  .mat-subtitle-2 {
    padding: 12px;
  }
}

/* -------------------------------------------------------------------------- */
/*  @ Toolbar
/* -------------------------------------------------------------------------- */

@media screen and (max-width: 599px) {
  .mat-toolbar-single-row {
    padding: 0 1px !important;
  }
}

/* -------------------------------------------------------------------------- */
/*  @ Tabs
/* -------------------------------------------------------------------------- */
.mat-mdc-tab-group {
  .mat-tab {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }

  /* No header */
  &.fuse-mat-no-header {
    .mat-mdc-tab-header {
      height: 0 !important;
      max-height: 0 !important;
      border: none !important;
      visibility: hidden !important;
      opacity: 0 !important;
    }
  }

  &:not(.mat-background-primary):not(.mat-background-accent) {
    .mat-mdc-tab-header {
      .mat-mdc-tab-label-container {
        box-shadow: inset 0 -1px var(--fuse-border);
      }
    }
  }

  .mat-mdc-tab-header {
    .mat-mdc-tab-label-container {
      margin: 2px;
    }
  } 

  .mat-mdc-tab-header-pagination {
    min-width: 20px;
  }

  .mat-mdc-tab-header .mdc-tab {
    min-width: fit-content;
    padding: 0 8px;
    background: #f5f5f5;
    margin-right: 2px;
  }

  .mdc-tab--active {
    //color: #495057 !important;
    //background-color: #dadfe824 !important;
    border-color: #dee2e6 #dee2e6 #fff !important;
    border: 1px solid #0000001f !important;
  }

  .mat-mdc-tab-body-content {
    padding: 2px;
  }

  @media screen and (max-width: 599px) {
	   .mat-mdc-tab-header-pagination-controls-enabled .mat-mdc-tab-header-pagination {
   		display: flex;
   	}
  }
}





.mat-mdc-tab .mdc-tab__text-label {
  @media screen and (max-width: 599px) {
    font-size: 16px;
    min-width: 48px;
    min-height: 48px;
    //display: inline !important;
    font-size: 16px;
    padding: 12px;
    color: rgba(0, 0, 0, 0.87) !important;
  }
}

/* -------------------------------------------------------------------------- */
/*  @ Textarea
/* -------------------------------------------------------------------------- */
textarea.mat-mdc-input-element {
  box-sizing: content-box !important;
}

@media screen and (max-width: 599px) {
  .mdc-text-field__input {
    min-width: 48px !important;
    min-height: 48px !important;
    display: inline !important;
    font-size: 16px !important;
    padding: 12px !important;
    margin: 0 !important;
  }
}

/* -------------------------------------------------------------------------- */
/*  @ Paginator
/* -------------------------------------------------------------------------- */
@media screen and (max-width: 599px) {
  .mat-mdc-paginator-page-size-label,
  .mat-mdc-paginator-range-label {
    min-width: 48px !important;
    min-height: 48px !important;
    display: inline !important;
    font-size: 16px !important;
    padding: 12px !important;
    margin: 0 !important;
  }
}

/* -------------------------------------------------------------------------- */
/*  @ Tooltip
/* -------------------------------------------------------------------------- */

// .mat-mdc-tooltip .mdc-tooltip__surface {
//     background-color: var(--fuse-text-default) !important;
//     color: white;

//     .dark & {
//         background-color: var(--fuse-text-secondary) !important;
//         color: var(--fuse-bg-default) !important;
//     }
// }

// EXAMPLE OF HOW TO CHANGE THE LINE HEIGHT OF THE DIALOG CONTENT
// .mat-mdc-dialog-container .mdc-dialog__content {
// 	--mdc-dialog-supporting-text-line-height: 16px;
// }

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs
  > .mat-mdc-tab-header
  .mat-mdc-tab {
  flex-grow: 0 !important;
}
