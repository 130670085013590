@mixin rp-cms-editor-theme($theme) {
  .rpblockbuilder-preview {
    --preview-padding-v: 20px;
    --preview-padding-h: 30px;
    --accent-color: #69b8e5;
    --merge-tags-accent-color: #7b61ff;
    --display-condition-accent-color: #7b61ff;
    --pointer-events-dnd: auto;
    padding: var(--preview-padding-v) var(--preview-padding-h);
  }
  .rpblockbuilder-preview.dnd-active {
    --pointer-events-dnd: none;
  }
  .rpblockbuilder-preview.editor-mobile .fade-mobile {
    opacity: 0.25;
  }
  .rpblockbuilder-preview.editor-mobile .hide-mobile {
    display: none !important;
  }
  .rpblockbuilder-preview.editor-mobile .rpblockbuilder-page-layout {
    position: relative;
  }
  .rpblockbuilder-preview.editor-mobile
    .rpblockbuilder-page-layout:not(.grid-active):after {
    content: " ";
    z-index: 99;
    position: absolute;
    top: 0;
    left: 0;
    right: 1px;
    bottom: 0;
    outline: #ccc dashed 1px;
    pointer-events: var(--pointer-events-dnd);
  }
  .rpblockbuilder-preview.editor-mobile .row:not(.no-stack) .u_column {
    max-width: unset;
    flex: unset;
    width: 100%;
  }
  .rpblockbuilder-preview.editor-mobile.enable-row-layer-outer-space {
    --preview-padding-h: 50px;
  }
  .rpblockbuilder-preview.editor-mobile.enable-row-layer-outer-space
    .rpblockbuilder-layer-drag-rows {
    margin-right: -50px;
  }
  .rpblockbuilder-preview.editor-mobile.enable-row-layer-outer-space
    .rpblockbuilder-layer-controls.rpblockbuilder-layer-controls-rows {
    margin-right: -50px;
  }
  .rpblockbuilder-preview.editor-mobile.enable-row-layer-outer-space
    .rpblockbuilder-layer-controls.rpblockbuilder-layer-controls-rows.rpblockbuilder-layer-controls-left {
    margin-left: -50px;
  }
  .rpblockbuilder-preview.editor-mobile.enable-row-layer-outer-space
    .rpblockbuilder-layer-controls.rpblockbuilder-layer-controls-left {
    right: auto;
  }
  .rpblockbuilder-preview.editor-mobile.enable-row-layer-outer-space
    .rpblockbuilder-layer-selector-row
    .rpblockbuilder-layer-type {
    margin-right: -49px;
  }
  .rpblockbuilder-preview.editor-mobile.enable-row-layer-outer-space
    .rpblockbuilder-layer-selector-row:after {
    margin-left: -50px;
    margin-right: -50px;
  }
  .rpblockbuilder-preview.editor-mobile.enable-row-layer-outer-space
    .rpblockbuilder-layer-selector-row.rpblockbuilder-layer-selector-active:after {
    left: 0px;
    right: 0px;
  }
  .rpblockbuilder-preview.editor-mobile.enable-row-layer-outer-space
    .rpblockbuilder-layer-selector-row
    .rpblockbuilder-layer-collaboration-type {
    margin-right: -50px;
  }
  .rpblockbuilder-preview.editor-desktop .fade-desktop {
    opacity: 0.25;
  }
  .rpblockbuilder-preview.editor-tablet .fade-tablet {
    opacity: 0.25;
  }
  .rpblockbuilder-preview.editor-desktop.enable-row-layer-outer-space,
  .rpblockbuilder-preview.editor-tablet.enable-row-layer-outer-space {
    --preview-padding-h: 30px;
  }
  .rpblockbuilder-preview.editor-desktop.enable-row-layer-outer-space
    .rpblockbuilder-layer-drag-rows,
  .rpblockbuilder-preview.editor-tablet.enable-row-layer-outer-space
    .rpblockbuilder-layer-drag-rows {
    right: -30px;
  }
  .rpblockbuilder-preview.editor-desktop.enable-row-layer-outer-space
    .rpblockbuilder-layer-controls.rpblockbuilder-layer-controls-rows,
  .rpblockbuilder-preview.editor-tablet.enable-row-layer-outer-space
    .rpblockbuilder-layer-controls.rpblockbuilder-layer-controls-rows {
    right: -30px;
  }
  .rpblockbuilder-preview.editor-desktop.enable-row-layer-outer-space
    .rpblockbuilder-layer-controls.rpblockbuilder-layer-controls-rows.rpblockbuilder-layer-controls-left,
  .rpblockbuilder-preview.editor-tablet.enable-row-layer-outer-space
    .rpblockbuilder-layer-controls.rpblockbuilder-layer-controls-rows.rpblockbuilder-layer-controls-left {
    left: -30px;
  }
  .rpblockbuilder-preview.editor-desktop.enable-row-layer-outer-space
    .rpblockbuilder-layer-controls.rpblockbuilder-layer-controls-left,
  .rpblockbuilder-preview.editor-tablet.enable-row-layer-outer-space
    .rpblockbuilder-layer-controls.rpblockbuilder-layer-controls-left {
    right: auto;
  }
  .rpblockbuilder-preview.editor-desktop.enable-row-layer-outer-space
    .rpblockbuilder-layer-selector-row
    .rpblockbuilder-layer-type,
  .rpblockbuilder-preview.editor-tablet.enable-row-layer-outer-space
    .rpblockbuilder-layer-selector-row
    .rpblockbuilder-layer-type {
    right: -32px;
  }
  .rpblockbuilder-preview.editor-desktop.enable-row-layer-outer-space
    .rpblockbuilder-layer-selector-row:after,
  .rpblockbuilder-preview.editor-tablet.enable-row-layer-outer-space
    .rpblockbuilder-layer-selector-row:after {
    left: -28px;
    right: -28px;
  }
  .rpblockbuilder-preview.editor-desktop.enable-row-layer-outer-space
    .rpblockbuilder-layer-selector-row.rpblockbuilder-layer-selector-active:after,
  .rpblockbuilder-preview.editor-tablet.enable-row-layer-outer-space
    .rpblockbuilder-layer-selector-row.rpblockbuilder-layer-selector-active:after {
    left: 0px;
    right: 0px;
  }
  .rpblockbuilder-preview.design-popup
    .rpblockbuilder-page-layout
    .u-popup-container {
    left: calc(var(--preview-padding-h) * -1);
    right: calc(var(--preview-padding-h) * -1);
    padding: 0 var(--preview-padding-h);
    background-color: unset;
    z-index: 100;
  }
  .rpblockbuilder-preview.design-popup
    .rpblockbuilder-page-layout
    .u-popup-container::before {
    position: absolute;
    content: " ";
    top: 0;
    bottom: 0;
    left: var(--preview-padding-h);
    right: var(--preview-padding-h);
    z-index: -1;
    pointer-events: none;
  }
  .rpblockbuilder-preview.design-popup
    .rpblockbuilder-page-layout
    .u-popup-container
    .u-popup-main {
    box-sizing: content-box;
    pointer-events: all;
  }
  .rpblockbuilder-preview.design-popup
    .rpblockbuilder-page-layout
    .u-popup-container
    .u-popup-main
    .u-popup-content {
    margin: 0 calc(var(--preview-padding-h) * -1);
    padding: 0 var(--preview-padding-h);
  }
  .rpblockbuilder-layer-selector {
    position: relative;
    // top: 0;
    // left: 0;
    // right: 0;
    // bottom: 0;
    width: 100%;
    border: 1px solid #a5d4ef;
    //visibility: hidden;
  }
  .rpblockbuilder-layer-selector:hover {
    visibility: visible;
  }
  .rpblockbuilder-layer {
    --accent-color: #a5d4ef;
    position: relative;
  }
  .rpblockbuilder-layer.rpblockbuilder-layer-selected {
    --accent-color: #69b8e5;
    user-select: none;
  }
  .rpblockbuilder-layer.rpblockbuilder-layer-selected
    > .rpblockbuilder-layer-selector:after {
    opacity: 1;
    outline: var(--accent-color) solid 2px;
    outline-offset: -1px;
  }
  .rpblockbuilder-layer.rpblockbuilder-layer-selected
    > .rpblockbuilder-layer-selector
    .rpblockbuilder-layer-drag {
    visibility: visible;
  }
  .rpblockbuilder-layer.rpblockbuilder-layer-selected
    > .rpblockbuilder-layer-selector
    .rpblockbuilder-layer-resizer {
    background-color: var(--accent-color);
    opacity: 0.5;
  }
  .rpblockbuilder-layer.rpblockbuilder-layer-selected
    > .rpblockbuilder-layer-selector
    .rpblockbuilder-layer-resizer:hover {
    opacity: 1;
  }
  .rpblockbuilder-layer.rpblockbuilder-layer-selected
    > .rpblockbuilder-layer-selector
    .rpblockbuilder-layer-resizer.active {
    opacity: 1;
  }
  .rpblockbuilder-layer.rpblockbuilder-layer-selected
    > .rpblockbuilder-layer-selector
    .rpblockbuilder-layer-controls {
    visibility: visible;
  }
  .rpblockbuilder-layer.rpblockbuilder-layer-selected
    .rpblockbuilder-layer-add-row {
    visibility: visible;
    background-color: var(--accent-color);
  }
  .rpblockbuilder-layer.rpblockbuilder-layer-selected .mce-edit-focus {
    outline: 0;
  }
  .rpblockbuilder-layer.rpblockbuilder-layer-resizing
    .rpblockbuilder-layer
    .rpblockbuilder-layer-selector
    .rpblockbuilder-layer-type {
    visibility: hidden;
  }
  .rpblockbuilder-layer.rpblockbuilder-layer-resizing
    .rpblockbuilder-layer
    .rpblockbuilder-layer-selector:after {
    opacity: 0 !important;
  }
  .rpblockbuilder-layer-group {
    width: 100%;
  }
  .rpblockbuilder-layer-group.rpblockbuilder-layer-group-content:after {
    content: " ";
    z-index: 99;
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 1px;
  }
  .rpblockbuilder-layer-group.rpblockbuilder-layer-group-content.rpblockbuilder-layer-group-grid:after {
    opacity: 1;
    right: 1px;
    left: 0;
    outline: #ccc dashed 1px;
    outline-offset: -1px;
  }
  .rpblockbuilder-layer-group.rpblockbuilder-layer-group-content.rpblockbuilder-layer-group-active:after {
    opacity: 1;
    left: 0px;
    right: 0px;
    z-index: 100;
    border-right: 0px;
    outline: var(--accent-color) solid 2px;
    outline-offset: -1px;
    pointer-events: var(--pointer-events-dnd);
  }
  .rpblockbuilder-layer-group-row
    > .rpblockbuilder-layer-selected
    .rpblockbuilder-layer-selector:hover
    .rpblockbuilder-layer-drag-contents {
    visibility: hidden;
  }
  .rpblockbuilder-layer-selectable.rpblockbuilder-layer-selected-inactive
    > .rpblockbuilder-layer-selector:after {
    opacity: 1;
  }
//   .rpblockbuilder-layer-selectable .rpblockbuilder-layer-selector:after {
//     content: " ";
//     opacity: 0;
//     z-index: 100;
//     //position: absolute;
//     top: 1px;
//     left: 1px;
//     right: 1px;
//     bottom: 1px;
//     outline: var(--accent-color) solid 2px;
//     outline-offset: -1px;
//     pointer-events: var(--pointer-events-dnd);
//   }
  .rpblockbuilder-layer-selectable
    .rpblockbuilder-layer-selector.rpblockbuilder-layer-selector-row.rpblockbuilder-layer-selector-active:after {
    top: 0px;
    left: 0px;
    outline: #ccc dashed 1px;
    outline-offset: -1px;
    z-index: 98;
    opacity: 1;
  }
  .rpblockbuilder-layer-selectable
    .rpblockbuilder-layer-selector.rpblockbuilder-layer-selector-row.rpblockbuilder-layer-selector-active:before {
    content: " ";
    opacity: 1;
    z-index: 100;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border-bottom: 2px solid #ccc;
  }
  .rpblockbuilder-layer-selectable
    .rpblockbuilder-layer-selector.rpblockbuilder-layer-selector-row.rpblockbuilder-layer-selector-active.rpblockbuilder-layer-selector-first:before {
    top: -1px;
    border-top: 2px solid #ccc;
  }
  .rpblockbuilder-layer-selectable .rpblockbuilder-layer-selector:hover:after {
    opacity: 1;
  }
  // .rpblockbuilder-layer-selectable
  //   .rpblockbuilder-layer-selector:hover
  //   .rpblockbuilder-layer-drag,
  // .rpblockbuilder-layer-selectable
  //   .rpblockbuilder-layer-selector:hover
  //   .rpblockbuilder-layer-type,
  // .rpblockbuilder-layer-selectable
  //   .rpblockbuilder-layer-selector:hover
  //   .rpblockbuilder-layer-add-row {
  //   visibility: visible;
  // }
  .rpblockbuilder-layer-selectable.rpblockbuilder-layer-merge-tags:not(
      .rpblockbuilder-layer-collaboration
    ).invalid {
    --merge-tags-accent-color: #ff5f57;
  }
  .rpblockbuilder-layer-selectable.rpblockbuilder-layer-merge-tags:not(
      .rpblockbuilder-layer-collaboration
    )
    > .rpblockbuilder-layer-selector
    .rpblockbuilder-layer-control.rpblockbuilder-merge-tags {
    --accent-color: var(--merge-tags-accent-color);
    color: var(--accent-color) !important;
  }
  .rpblockbuilder-layer-selectable.rpblockbuilder-layer-merge-tags:not(
      .rpblockbuilder-layer-collaboration
    )
    > .rpblockbuilder-layer-selector
    .rpblockbuilder-layer-type
    .icon.dynamic-content {
    --accent-color: var(--merge-tags-accent-color);
  }
  .rpblockbuilder-layer-selectable.rpblockbuilder-layer-merge-tags:not(
      .rpblockbuilder-layer-collaboration
    )
    > .rpblockbuilder-layer-selector
    .rpblockbuilder-layer-type {
    --accent-color: #b0a0ff;
  }
  .rpblockbuilder-layer-selectable
    > .rpblockbuilder-layer-selector
    .rpblockbuilder-layer-control.rpblockbuilder-merge-tags {
    --accent-color: var(--merge-tags-accent-color);
  }
  .rpblockbuilder-layer-selectable.rpblockbuilder-layer-display-condition
    .rpblockbuilder-layer-control.rpblockbuilder-display-condition {
    font-size: 110%;
  }
  .rpblockbuilder-layer-selectable.rpblockbuilder-layer-display-condition:not(
      .rpblockbuilder-layer-collaboration
    ).invalid {
    --display-condition-accent-color: #ff5f57;
  }
  .rpblockbuilder-layer-selectable.rpblockbuilder-layer-display-condition:not(
      .rpblockbuilder-layer-collaboration
    )
    > .rpblockbuilder-layer-selector
    .rpblockbuilder-layer-control.rpblockbuilder-display-condition {
    --accent-color: var(--display-condition-accent-color);
    color: var(--accent-color) !important;
  }
  .rpblockbuilder-layer-selectable.rpblockbuilder-layer-display-condition:not(
      .rpblockbuilder-layer-collaboration
    )
    > .rpblockbuilder-layer-selector
    .rpblockbuilder-layer-type
    .icon.dynamic-content {
    --accent-color: var(--display-condition-accent-color);
  }
  .rpblockbuilder-layer-selectable.rpblockbuilder-layer-display-condition:not(
      .rpblockbuilder-layer-collaboration
    )
    > .rpblockbuilder-layer-selector
    .rpblockbuilder-layer-type {
    --accent-color: #b0a0ff;
  }
  .rpblockbuilder-layer-selectable
    > .rpblockbuilder-layer-selector
    .rpblockbuilder-layer-control.rpblockbuilder-display-condition {
    --accent-color: var(--display-condition-accent-color);
  }
  .rpblockbuilder-layer-selectable.rpblockbuilder-layer-collaboration {
    --accent-color: #a5d4ef;
  }
  .rpblockbuilder-layer-selectable.rpblockbuilder-layer-collaboration.rpblockbuilder-layer-selected {
    --accent-color: #69b8e5;
  }
  .rpblockbuilder-layer-selectable.rpblockbuilder-layer-collaboration
    .rpblockbuilder-layer-control:hover {
    --accent-color: #309ddb;
  }
  .rpblockbuilder-layer-selectable
    > .rpblockbuilder-layer-selector
    .rpblockbuilder-layer-control.rpblockbuilder-comment
    .rpblockbuilder-comment-count {
    background-color: #ff0000;
    color: #fff;
    font-size: 9px;
    position: absolute;
    top: 2px;
    right: 2px;
    width: 14px;
    height: 14px;
    line-height: 14px;
    border-radius: 100%;
  }
  .rpblockbuilder-layer-type {
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Open Sans", sans-serif;
    position: absolute;    
    right: 0px;
    background-color: var(--accent-color);
    color: #fff;
    z-index: 101;
    font-size: 12px;
    line-height: 22px;
    height: 22px;
    padding: 0 10px;
  }
  .rpblockbuilder-layer-type > .icon {
    width: 22px;
    text-align: center;
    background-color: var(--accent-color);
  }
  .rpblockbuilder-layer-type > .text {
    padding: 0px 10px;
  }
  .rpblockbuilder-layer-controls {
    //visibility: hidden;
    display: inline-block;
    position: absolute;
    bottom: auto;
    right: 0px;
    padding: 5px;
    text-align: right;
    border: 1px solid #dedede;
    border-radius: 2px;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.08);
    z-index: 112;
    white-space: nowrap;
    overflow: visible;    
    background-color: #fff;
  }
  .rpblockbuilder-layer-controls:hover {
    z-index: 125;
  }
  .rpblockbuilder-layer-controls:empty {
    display: none;
  }
  .rpblockbuilder-layer-controls * {
    vertical-align: middle;
  }
  .rpblockbuilder-layer-controls > .arrow-up {
    position: absolute;
    top: -14px;
    right: 10px;
    pointer-events: none;
  }
  .rpblockbuilder-layer-controls [data-tooltipped] {
    display: inline-block !important;
    margin: 5px;
  }
  .rpblockbuilder-layer-controls
    [data-tooltipped]
    .rpblockbuilder-layer-control {
    margin: 0;
  }
  .rpblockbuilder-layer-controls .rpblockbuilder-layer-control {
    --accent-color: #0087ee;
    position: relative;
    display: inline-block;
    height: 32px;
    width: 32px;
    line-height: 32px;
    font-size: 16px;
    margin: 5px;
    cursor: pointer;
    vertical-align: middle;
    border-radius: 2px;
    text-align: center;
  }
  .rpblockbuilder-layer-controls .rpblockbuilder-layer-control:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 2px;
  }
  .rpblockbuilder-layer-controls .rpblockbuilder-layer-control:hover {
    color: var(--accent-color) !important;
  }
  .rpblockbuilder-layer-controls .rpblockbuilder-layer-control:hover:before {
    background-color: var(--accent-color);
  }
  .rpblockbuilder-layer-controls
    .rpblockbuilder-layer-control:hover.rpblockbuilder-delete {
    --accent-color: #ff5f57;
  }
  .rpblockbuilder-layer-controls
    .rpblockbuilder-layer-control:hover.rpblockbuilder-delete:before {
    opacity: 0.14;
  }
  .rpblockbuilder-layer-controls .rpblockbuilder-layer-control.disabled {
    cursor: not-allowed;
  }
  .rpblockbuilder-layer-controls .rpblockbuilder-layer-control.disabled:before {
    background-color: transparent;
  }
  .rpblockbuilder-layer-controls .rpblockbuilder-layer-control.disabled > * {
    opacity: 0.5;
  }
  .rpblockbuilder-layer-controls.light-theme
    .rpblockbuilder-layer-control:hover:before {
    opacity: 0.08;
  }
  .rpblockbuilder-layer-controls.dark-theme
    .rpblockbuilder-layer-control:hover:before {
    opacity: 0.14;
  }
  .rpblockbuilder-layer-controls.rpblockbuilder-layer-controls-left {
    left: 0px;
    right: auto;
  }
  .rpblockbuilder-layer-controls.rpblockbuilder-layer-controls-left.rpblockbuilder-layer-controls-rows {
    right: auto;
  }
  .rpblockbuilder-layer-controls .rpblockbuilder-layer-control-separator {
    display: inline-block;
    height: 32px;
    padding: 5px 0;
    margin: 5px;
    text-align: center;
  }
  .rpblockbuilder-layer-controls
    .rpblockbuilder-layer-control-separator
    > span {
    display: inline-block;
    width: 1px;
    height: 100%;
    background-color: rgba(37, 47, 61, 0.25);
  }
  .rpblockbuilder-layer-add-row {
    //visibility: hidden;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: -20px;
    background-color: #69b8e5;
    opacity: 0.5;
    color: #fff;
    width: 100px;
    left: calc(50% - 50px);
    text-align: center;
    z-index: 112;
    cursor: pointer;
  }
  .rpblockbuilder-layer-add-row.rpblockbuilder-layer-add-row-top {
    bottom: auto;
    top: -20px;
  }
  .rpblockbuilder-layer-add-row:hover {
    opacity: 1;
  }
  .rpblockbuilder-layer-add-row > div {
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
  .rpblockbuilder-layer-resize-group {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
  }
  .rpblockbuilder-layer-resize-group > .container {
    position: relative;
    height: 100%;
    pointer-events: none;
  }
  .rpblockbuilder-layer-resize-group > .container > div {
    position: relative;
    height: 100%;
    pointer-events: none;
  }
  .rpblockbuilder-layer-resize-group
    > .container
    > div
    > .rpblockbuilder-layer-resizer {
    pointer-events: all;
  }
  .rpblockbuilder-layer-collaboration-type {
    position: absolute;
    top: -10px;
    right: -22px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 36px;
    font-size: 14px;
    color: #fff;
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.2));
    z-index: 111;
  }
  .rpblockbuilder-layer-collaboration-type > .icon-bg {
    position: absolute;
    color: var(--accent-color);
    font-size: 36px;
    stroke: #fff;
    stroke-width: 5%;
    z-index: -1;
  }
  .rpblockbuilder-layer-collaboration-type > .icon-main {
    margin-top: -7px;
    margin-left: 1px;
  }
  .rpblockbuilder-layer-collaboration-popover-container {
    font-family: "Open Sans", sans-serif;
    position: absolute;
    top: calc((-1 * var(--editor-offset-y, 0px)) - 10px);
    right: -32px;
  }
  .rpblockbuilder-layer-collaboration-popover-container
    > .rpblockbuilder-layer-collaboration-popover {
    position: fixed;
    user-select: text;
    z-index: 200;
  }
  .rpblockbuilder-layer-drag {
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    background-color: var(--accent-color);
    color: #fff;
    z-index: 111;
    cursor: move;
    cursor: grab;
    cursor: -webkit-grab;
  }
  .rpblockbuilder-layer-drag-rows {
    right: 0px;
    height: 40px;
    width: 40px;
    font-size: 20px;
    line-height: 40px;
    border-radius: 100% 0 0 100%;
    margin-top: -20px;
  }
  .rpblockbuilder-layer-drag-contents {
    right: -17px;
    height: 30px;
    width: 30px;
    line-height: 31px;
    border-radius: 100%;
    margin-top: -15px;
    font-size: 16px;
  }
  .rpblockbuilder-placeholder {
    --accent-color: #69b8e5;
    position: relative;
    font-family: "Open Sans", sans-serif;
    z-index: 101;
  }
  .rpblockbuilder-placeholder a,
  .rpblockbuilder-placeholder a:hover {
    text-decoration: none;
  }
  .rpblockbuilder-placeholder:before {
    display: none;
    content: attr(data-name);
    font-size: 11px;
    color: #fff;
    background-color: var(--accent-color);
    line-height: 10px;
    position: absolute;
    top: -10px;
    left: 50%;
    margin-left: -50px;
    width: 100px;
    text-align: center;
    padding: 5px;
    z-index: 101;
    border-radius: 20px;
    overflow: hidden;
  }
  .rpblockbuilder-placeholder:after {
    display: none;
    content: " ";
    z-index: 100;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 0px;
    outline: var(--accent-color) solid 3px;
    outline-offset: -2px;
  }
  .rpblockbuilder-placeholder.rpblockbuilder-placeholder-active:after,
  .rpblockbuilder-placeholder.rpblockbuilder-placeholder-active:before {
    display: inline-block;
  }
  .rpblockbuilder-placeholder .rpblockbuilder-placeholder-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(222, 242, 250, 0.85);
    outline: #2faade dashed 1px;
    outline-offset: -1px;
    min-height: 100px;
    font-size: 13px;
    color: #2faade;
    padding: 20px;
    text-align: center;
  }
//   .mce-content-body {
//     z-index: 110;
//   }
//   .tox .tox-collection__item-label {
//     max-width: 250px;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//   }
//   .tox-menu {
//     white-space: nowrap !important;
//     max-height: 300px !important;
//     overflow-y: auto !important;
//   }
}
