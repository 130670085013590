@use "@angular/cdk";
@use "@angular/material" as mat;
@use "./variables" as *;

@import url("https://fonts.googleapis.com/css2?family=Exo&display=swap");

// Import Fuse core library that icludes mat.core() mixin for material
@import "@fuse/scss/core";

// Import app.theme.scss
@import "app/app.theme";
@import "_animation";

// angular-CLI does not recognize the @import by the browser, it even loads, but the settings does not apply. thats why we need to add the third party styles in the angular.json file
//@import "../../node_modules/angular-calendar/css/angular-calendar.css";

@import "ngx-toastr/toastr";
@import "./app/shared/mat-table-responsive/mat-table-responsive.directive.scss";

//https://ngx-gallery.netlify.app/#/getting-started/lightbox
@import "@angular/cdk/overlay-prebuilt.css";

@import "../app/shared/mat-table-responsive/mat-table-responsive.directive.scss";

// @import "@mat-datetimepicker/core/datetimepicker/datetimepicker-theme.scss";

// Using the $theme variable from the pre-built theme you can call the theming function

#appBody {
  overflow: hidden;
}

// Message boxes, boxxed message & effects
.message-boxxed {
  background-color: #f5f5f5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
  border: 2px solid #ccc;
  padding: 8px;
  font-weight: bold;
  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 10px 6px -6px #777;
}

// ..boxxed message & effects

.text-shadow-white {
  text-shadow: -3px 3px 2px rgba(255, 255, 255, 1);
}

.sub-header {
  font-family: "Exo", sans-serif;
  margin-bottom: 5px;
  border-left-style: solid;
  border-left-width: 8px;
  font-weight: bold;
  background-color: #cfd8dc;
  padding: 4px;
  font-size: 0.9em;

  @media screen and (max-width: 599px) {
    font-size: 0.7em;
  }

  &.sub-header-rp {
    background-color: map-get($ribbon-colors, backgroundColor);
    border-left-color: map-get($ribbon-colors, borderLeftColor);
    color: map-get($ribbon-colors, textColor);
  }

  &.sub-header-rp-reverse {
    background-color: map-get($ribbon-colors-reverse, backgroundColor);
    border-left-color: map-get($ribbon-colors-reverse, borderLeftColor);
    color: map-get($ribbon-colors-reverse, textColor);
  }

  &.sub-header-xl {
    text-transform: uppercase;
    font-size: 1.3em;
  }

  &.sub-header-error {
    background-color: #ff00001a;
    border-left-color: red;
  }

  &.sub-header-dark-error {
    background-color: #ff000040;
    border-left-color: red;
  }

  &.sub-header-success {
    background-color: #04ff001a;
    border-left-color: green;
  }

  &.sub-header-dark-success {
    background-color: #03da0040;
    border-left-color: green;
  }

  &.sub-header-warning {
    background-color: #ffeb0038;
    border-left-color: orange;
  }

  &.sub-header-highlight {
    background-color: #ffff0050;
    border-left-color: yellow;
  }

  &.sub-header-dark-warning {
    background-color: #ffeb0076;
    border-left-color: orange;
  }

  &.sub-header-blue-grey {
    background-color: #b0bec5;
    border-left-color: #5c7f90;
  }

  &.sub-header-light-info {
    background-color: #dee5ef;
    border-left-color: #a3c0ee;
  }

  .mat-mdc-card-title {
    margin-bottom: 0 !important;
  }
}

.mat-mdc-form-field {
  font-size: 14px !important;

  @media screen and (max-width: 599px) {
    font-size: 1em;
  }
}

.width-fit {
  width: fit-content;
}

.with-icon {
  display: flex;
  align-items: center;
}

.btn-narrow,
.btn-narrower,
.btn-narrowest {
  white-space: nowrap;
}

.btn-narrow {
  line-height: 24px !important;
  min-height: 24px !important;
  max-height: 24px !important;
  height: 24px !important;

  box-shadow: 0 0 0 0 #0003, 0 0 0 0 #00000024, 0 0 0 0 #0000001f;
  padding: 0 20px !important;
  border-radius: 5px !important;
}

.btn-narrower {
  line-height: 20px !important;
  padding: 2px 4px !important;
  min-width: min-content !important;
}

.btn-narrowest {
  min-width: auto !important;
  line-height: normal !important;
  font-size: 12px;
  padding: 2px 10px !important;
  min-width: auto !important;
  border-radius: 4px !important;
  max-height: 20px;
  min-height: 20px;
  height: 20px !important;

  @media screen and (max-width: 599px) {
    font-size: 16px;
  }
}

.btn-round {
  box-shadow: 0 0 0 0 #0003, 0 0 0 0 #00000024, 0 0 0 0 #0000001f;
  padding: 0 20px !important;
  border-radius: 9999px !important;
}

.btn-round-narrow {
  box-shadow: 0 0 0 0 #0003, 0 0 0 0 #00000024, 0 0 0 0 #0000001f;
  padding: 0 20px !important;
  border-radius: 9999px !important;
  line-height: 24px !important;
  height: 24px !important;
}

.btn-ribbon {
  background-color: map-get($ribbon-buttons, backgroundColor) !important;
  color: map-get($ribbon-buttons, textColor) !important;
}

//.bgWhite {background-color: #fff;}

.ml-3 {
  margin-left: 3px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

//tr.mat-header-row { height: auto !important;}

@media print {
  .no-print,
  .screen-only,
  .noPrint,
  .noprint,
  .mat-mdc-tab-header {
    display: none !important;
  }
}

@media screen {
  .print-only {
    display: none !important;
  }
}

.capitalize {
  text-transform: capitalize;
}

.headerRibbon {
  height: 45px;
  display: flex;
  align-items: center;
  font-weight: 900;
}

.btn-readOnly {
  box-sizing: border-box;
  outline: none;
  border: none;
  line-height: 21px !important;
  padding: 0 5px;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  overflow: visible;
  transform: translate3d(0, 0, 0);
  transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),
    box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.snackBarBg-success {
  background-color: #4fa34f !important;
  color: white !important;

  .mat-mdc-snack-bar-action {
    color: white !important;
  }
}

.snackBarBg-warning {
  background-color: orange !important;
  color: white !important;

  .mat-mdc-snack-bar-action {
    color: white !important;
  }
}

.snackBarBg-error {
  background-color: red !important;
  color: white !important;

  .mat-mdc-snack-bar-action {
    color: white !important;
  }
}

.mat-mdc-raised-button[disabled][disabled] {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.mat-mdc-header-cell {
  color: #000;
}

.nowrap {
  white-space: nowrap;

  /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
  .mat-chip-list-wrapper {
    flex-wrap: nowrap !important;
  }
}

.mat-sort-header-arrow {
  color: #ffffff;
}

.mat-mdc-tab-header {
  background: white;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-body-wrapper {
  background: white;
  padding: 10px;

  @media screen and (max-width: 599px) {
    padding: 10px 2px;
  }
}

// th.mat-mdc-header-cell:first-of-type,
// td.mat-mdc-cell:first-of-type,
// td.mat-mdc-footer-cell:first-of-type {
// 	padding-left: 4px !important;
// }

// th.mat-mdc-header-cell:last-of-type,
// td.mat-mdc-cell:last-of-type,
// td.mat-mdc-footer-cell:last-of-type {
//   padding-right: 4px !important;
// }

.tblEvents {
  th.mat-mdc-header-cell:first-of-type,
  td.mat-mdc-cell:first-of-type,
  td.mat-mdc-footer-cell:first-of-type {
    padding-left: 4px;
  }
}

.tooltipPreLine {
  white-space: pre-line;
  font-size: 1rem;
}

.matTooltip {
  font-size: 14px !important;

  @media screen and (max-width: 599px) {
    font-size: 16px !important;
  }
}

.mat-mdc-tooltip.whiteTooltip,
.mat-mdc-tooltip.white-tooltip {
  background-color: #ffffff !important;
  color: #000 !important;
  border: 1px solid #cacaca;
}

.mat-mdc-tooltip.redTooltip,
.mat-mdc-tooltip.red-tooltip {
  background-color: #f33737 !important;
}

.mat-mdc-tooltip.greenTooltip,
.mat-mdc-tooltip.green-tooltip {
  background-color: #4caf50 !important;
  color: #fff;
}

.mat-mdc-tooltip.blueTooltip,
.mat-mdc-tooltip.blue-tooltip {
  background-color: #2196f3 !important;
  color: #fff;
}

.mat-mdc-tooltip.yellowTooltip,
.mat-mdc-tooltip.yellow-tooltip {
  background-color: yellow !important;
  color: black !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of tooltip that may no longer apply for the MDC version.*/
.mat-tooltip-yellow {
  color: yellow !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of tooltip that may no longer apply for the MDC version.*/

.mat-tooltip-big {
  font-size: 14px;

  @media screen and (max-width: 599px) {
    font-size: 16px;
  }
}

//BUTTON SPINNER
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 24px;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}

.spinner {
  padding-left: 45px !important;
  width: auto !important;
}

// BOOTSTRAP TABLE CSS
// https://github.com/twbs/bootstrap/blob/v4-dev/dist/css/bootstrap.css#L2079-L224

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

/* For desktop: */
.col-1 {
  width: 8.33%;
}

.col-2 {
  width: 16.66%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.33%;
}

.col-5 {
  width: 41.66%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.33%;
}

.col-8 {
  width: 66.66%;
}

.col-9 {
  width: 75%;
}

.col-10 {
  width: 83.33%;
}

.col-11 {
  width: 91.66%;
}

.col-12 {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  border-collapse: collapse !important;
}

.table th,
.table td {
  //padding: 0.5rem;
  vertical-align: middle;
  border-top: 1px solid #eceeef;
  height: 40px;
}

.table thead th {
  vertical-align: middle;
  //border-bottom: 2px solid #eceeef;
  //font-size:medium;
  border: 1px solid #eceeef;
}

.table tbody + tbody {
  border-top: 2px solid #eceeef;
}

.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-xs th,
.table-xs td {
  padding: 0.2rem;
  height: auto;
}

.table-bordered {
  border: 1px solid #eceeef;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #eceeef;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.01);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #dff0d8;
}

.table-hover .table-success:hover {
  background-color: #d0e9c6;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #d0e9c6;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #d9edf7;
}

.table-hover .table-info:hover {
  background-color: #c4e3f3;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #c4e3f3;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fcf8e3;
}

.table-hover .table-warning:hover {
  background-color: #faf2cc;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #faf2cc;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f2dede;
}

.table-hover .table-danger:hover {
  background-color: #ebcccc;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #ebcccc;
}

.thead-inverse th {
  color: #fff;
  background-color: #292b2c;
}

.thead-default th {
  color: #464a4c;
  background-color: #eceeef;
}

.table-inverse {
  color: #fff;
  background-color: #292b2c;
}

.table-inverse th,
.table-inverse td,
.table-inverse thead th {
  border-color: #fff;
}

.table-inverse.table-bordered {
  border: 0;
}

.table-responsive {
  // display: block;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive.table-bordered {
  border: 0;
}

.table .thead-dark th {
  color: #fff;
  background-color: #5c616f;
  border-color: #454d55;
}

.table .thead-dark-grey th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

// BOOTSTRAP Button CSS

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

//BOOTSTRAP ALERT CSS
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus,
.btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus,
.btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus,
.btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 8px 16px !important;
  font-size: 20px !important;
  line-height: 30px !important;
  margin: 2px 4px !important;
  min-height: 50px !important;
  max-height: 50px !important;
  font-weight: 400 !important;
}

.btn-xs,
.btn-group-xs > .btn {
  height: 20px !important;
  min-height: 20px !important;
  max-height: 20px !important;
  min-width: 50px !important;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 2px 8px !important;
  height: 24px !important;
  min-height: 24px !important;
  max-height: 24px !important;
}

.btn-md,
.btn-group-md > .btn {
  padding: 2px 12px !important;
  height: 30px !important;
  min-height: 30px !important;
  max-height: 30px !important;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

//input-gorup bootstrap
.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation)
  > .custom-file:not(:last-child)
  .custom-file-label,
.input-group:not(.has-validation)
  > .custom-file:not(:last-child)
  .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > .form-control:nth-last-child(n + 3),
.input-group.has-validation > .custom-select:nth-last-child(n + 3),
.input-group.has-validation
  > .custom-file:nth-last-child(n + 3)
  .custom-file-label,
.input-group.has-validation
  > .custom-file:nth-last-child(n + 3)
  .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: -ms-flexbox;
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.25rem 0.55rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

// .BOOTSTRAP

// TV View Stuff
body.inTVView {
  #dvPageHeader,
  #dirTabMenu > .mat-mdc-tab-group > .mat-mdc-tab-header {
    display: none !important;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.mat-mdc-raised-button.accent,
.mat-mdc-raised-button.mat-accent {
  background-color: #cf1d2c !important;
  color: #fff !important;
}

// .mat-row a {
// 	color: #3e75ff !important;
// 	text-decoration: none;
// }

// @media screen and (max-width: 959px){
// 	#appBody{
// 		padding-bottom: 100px;
// 	}
// }

// @media only screen and (max-width: 900px) {
// 	#appBody {
// 		padding-bottom: 55px;
// 	}
// }

// mat-dialog-container paddings
.p8-dialog-container .mat-mdc-dialog-container {
  padding: 8px;
}

.full-width-dialog .mat-mdc-dialog-container {
  padding: 0;
}

.full-screen .mat-mdc-dialog-container {
  height: 100vh;
  width: 100vw !important;
  max-width: none !important;
}

.hidden-adRow {
  display: none !important;
}

.ad-row {
  height: auto !important;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}

.demand-supply__sm--active {
  box-shadow: none;
  background-color: rgb(189 187 188 / 0.47);
}

//Bootstrap Alert CSS
//https://gist.github.com/EvanHerman/315952ebf371cb83ee9db6c54499933d
.clearfix {
  clear: both;
}
.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert h4 {
  margin-top: 0;
  color: inherit;
}

.alert .alert-link {
  font-weight: bold;
}

.alert > p,
.alert > ul {
  margin-bottom: 0;
}

.alert > p + p {
  margin-top: 5px;
}

.alert-dismissable,
.alert-dismissible {
  padding-right: 35px;
}

.alert-dismissable .close,
.alert-dismissible .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
}

.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
}

.alert-success hr {
  border-top-color: #c9e2b3;
}

.alert-success .alert-link {
  color: #2b542c;
}

.alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f;
}

.alert-info hr {
  border-top-color: #a6e1ec;
}

.alert-info .alert-link {
  color: #245269;
}

.alert-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
}

.alert-warning hr {
  border-top-color: #f7e1b5;
}

.alert-warning .alert-link {
  color: #66512c;
}

.alert-danger {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}

.alert-danger hr {
  border-top-color: #e4b9c0;
}

.alert-danger .alert-link {
  color: #843534;
}

//Bootstrap Form-control CSS
.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #42a948;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #a94442;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

// .form-control:focus {
// 	color: #495057;
// 	background-color: transparent;
// 	border-color: transparent;
// 	outline: 0;
// 	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
// }
.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

th.mat-mdc-header-cell.center {
  text-align: center;
}

.center.mat-sort-header-container {
  justify-content: center;
}

.center.mat-mdc-cell {
  text-align: center;
}

.center.mat-sort-header-container {
  justify-content: center !important;
}

.center .mat-sort-header-container {
  justify-content: center;
}

.center .mat-mdc-cell {
  text-align: center;
}

.center .mat-sort-header-container {
  justify-content: center !important;
}

.center.th {
  text-align: center !important;
}

.center.td {
  text-align: center !important;
}

th.center {
  text-align: center !important;
}

div[class^="us–style-manager-1buttonIframe"] {
  box-shadow: none !important;
}

.bold,
.bold * {
  font-weight: bold !important;
}

.listing-only,
.listing-only * {
  color: #333;
}

@media print {
  * {
    overflow: visible !important;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
#gameChangeForm .mat-select-value-text {
  max-width: 410px;
}

#multiDayCalendar .selected {
  background-color: #64a4b7 !important;
  border-radius: 50%;
}

.mat-datetimepicker-calendar-header,
.mat-datetimepicker-clock-cell.mat-datetimepicker-clock-cell-selected {
  background-color: #64a4b7;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-mdc-button-base.mat-button-wrap span.mat-button-wrapper {
  white-space: normal;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-label {
  padding: 0px 15px !important;
  min-width: fit-content !important;
  border-right: 1px solid #ddd;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-label-active {
  padding: 0px 10px !important;
  min-width: fit-content !important;
  color: #495057 !important;
  background-color: #dadfe824;
  border-color: #dee2e6 #dee2e6 #fff;
  border: 1px solid #0000001f;
}

.mat-mdc-tab-group.mat-primary .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
  background: #6168e7;
  width: 85px;
}

@media screen and (max-width: 599px) {
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-label[role^="tab"] {
    padding: 0 8px;
  }
}

.textBody {
  height: 30vh;
  overflow-y: auto;
}

// Hello Bar styles
.helloBar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: min-content;
  min-height: 61px;
  transform: translateY(-100%);
  // background-color: #28C891;
  // color: white;
  // text-align: center;
  transition: all 500ms ease 0s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;

  * {
    opacity: 0;
  }

  &.helloBar-visible {
    transform: translateY(0);

    * {
      opacity: 1;
    }
  }

  .helloButton {
    color: white;
    margin: 5px 10px;
    width: 24px;
    height: 24px;
    line-height: 24px;
  }

  .messageContainer {
    padding: 5px 5px 5px 24px;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
.green-progress .mat-progress-bar-fill::after {
  background-color: green !important;
}
mat-password-strength .mat-progress-bar-fill::after {
  background-color: #4caf50 !important;
}
.mat-password-field.mat-form-field.mat-focused .mat-form-field-label {
  color: #4caf50 !important;
}
.mat-password-field.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #4caf50 !important;
}

.green-psw-progress .mdc-linear-progress__bar-inner {
  border-color: #0dce31 !important;
}

.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ffd740;
}

mat-password-strength .mat-progress-bar-fill::after {
  background-color: #4caf50;
}

/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/

.orange-progress .mat-progress-bar-fill::after {
  background-color: orange !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/

.red-progress .mat-progress-bar-fill::after {
  background-color: red !important;
}

#mtDtimeline.mobile section .item article header {
  color: #000;
}

#mtDtimeline.mobile section .item article .body a {
  color: #3d5a8e !important;
}

.mtDtimeline {
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#1d2671),
      to(#c33764)
    )
    fixed;

  article,
  aside,
  footer,
  header,
  nav,
  section {
    color: #000;
  }

  //timeline
  fieldset {
    float: left;
    width: 150px;
  }

  form {
    margin-bottom: 50px;
  }

  .box {
    width: 350px;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 20px;
  }

  .centro {
    margin: 0 auto;
    width: 90%;
    padding-bottom: 70px;
  }

  #timeline-albe.vertical section .item .badge,
  #timeline-albe.vertical section header {
    cursor: initial !important;
  }

  #timeline-albe.vertical nav a {
    color: #000000 !important;
  }

  #timeline-albe.vertical section .item article::after {
    top: 38px !important;
  }

  #timeline-albe.vertical nav a:hover {
    color: #ef693a;
    border-bottom: 3px solid #ef693a;
    font-weight: 400;
  }
}

#membership {
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-select-disabled .mat-select-value,
  .mat-form-field-disabled mat-label {
    color: #000 !important;
  }
}

.avatarXL {
  width: 105px;
  min-width: 105px;
  height: 105px;
  line-height: 105px;
  border-radius: 50%;
}

.IDPicture {
  height: 150px;
  width: 138px;
}

.matIconButton-24px {
  width: 24px !important;
  height: 24px !important;
  line-height: 24px !important;
}

.u_content_menu a {
  padding: 5px 10px !important;
  background-color: #1a3668 !important;
  border-radius: 5px !important;
  color: #fff !important;
  margin: 4px;
}

.rp-chip {
  font-size: 10px;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: inline-flex;
  padding: 0 5px;
  border-radius: 16px;
  align-items: center;
  cursor: default;
  min-height: 20px;
  height: 1px;
  width: fit-content;

  @media screen and (max-width: 599px) {
    font-size: 16px;
  }
}

//.mat-ripple { display: none; }

.mouse-pointer {
  cursor: pointer;
}

// Advertismenet Junk
div.ad-slot {
  margin: 8px 4px;
  text-align: center;
}

#club-calendar {
  .cal-week-view .cal-event {
    color: #000 !important;
  }

  .cal-week-view .cal-event {
    white-space: normal !important;
  }
}

.viewDate {
  padding: 8px 12px;
  font-weight: normal;
  border-radius: 4px;
  height: 36px;
}

.facilitySearchResults {
  .mat-sort-header-arrow {
    color: #ffffff !important;
  }
}

.matSlider {
  /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
  .mat-slider-horizontal .mat-slider-thumb-label {
    right: -18px !important;
    top: -22px !important;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
  .mat-slider-thumb-label:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #cf1d2c transparent transparent transparent;
  }
}

#compliance {
  @media screen and (max-width: 768px) {
    .mat-expansion-panel-body {
      padding: 0 4px !important;
    }
  }
}

@media screen and (max-width: 768px) {
  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select:focus,
  textarea {
    font-size: 16px;
  }
}

// input, input:before, input:after {
//   -webkit-user-select: initial;
//   -khtml-user-select: initial;
//   -moz-user-select: initial;
//   -ms-user-select: initial;
//   user-select: initial;
// }

.divTeamsMatExpansionPanel .mat-expansion-panel-body {
  @media screen and (max-width: 599px) {
    padding: 0 !important;
  }
}

hr.normal {
  width: 100% !important;
  height: 1px !important;
  box-sizing: border-box !important;
}

.coaches-placeholder {
  display: block;
  background: #ccc;
  border: dotted 3px #999;
  min-height: 18px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.noBottomPadding {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }
}

.mergeStepper .mat-step-icon .mat-icon {
  padding: 3px !important;
}

.link {
  cursor: pointer;
}

#createEvent {
  @media screen and (max-width: 599px) {
    .mat-stepper-vertical .mat-vertical-stepper-header {
      padding: 4px;
    }

    .mat-vertical-content {
      padding: 0 4px 12px 8px;
    }

    .mat-mdc-card {
      padding: 4px;
    }
  }
}

.w-39-p {
  width: 39% !important;
}

// Use selector of the component for which you want to apply styles
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
detail .mat-tab-label-container {
  margin-left: 12px;

  @media screen and (max-width: 599px) {
    margin-left: 4px;
  }
}

@media screen and (min-device-width: 320px) and (max-device-height: 568px) and (-webkit-device-pixel-ratio: 2) {
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  detail .mat-tab-label {
    padding: 8px;
    min-width: 44px;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  detail
    .mat-tab-header-pagination-controls-enabled
    .mat-tab-header-pagination {
    display: none !important;
  }
}

@media screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  detail .mat-tab-label {
    padding: 8px;
    min-width: 49px;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
public-event-detail .mat-tab-label-container {
  margin-left: 0px;
}

.mat-date-range-input-start-wrapper,
.mat-date-range-input-end-wrapper {
  min-width: 90px !important;
}

.mat-date-range-input-end-wrapper {
  padding-left: 5px;
}

// AQW UNlayer
#u_body {
  min-height: auto !important;
}

.w-98-p {
  width: 98% !important;
}

.rp-raised-button {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  overflow: visible;
  transform: translate3d(0, 0, 0);
  transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),
    box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);

  .rp-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.mat-drawer,
.mat-sidenav {
  &[cdk-scrollable],
  &[cdkScrollable] {
    .mat-drawer-inner-container {
      overflow: visible;
    }
  }
}

.invRowSettings {
  .mat-expansion-panel-body {
    padding: 0 5px 5px !important;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-label {
    height: 30px;
    padding: 0 12px !important;
  }
}

.pulse-bg::after {
  opacity: 0;
  content: "";
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 6px solid #0000cc;
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ring2 0.5s 3;
  animation-delay: 1s;
}

@keyframes ring2 {
  0% {
    opacity: 0;
  }

  1% {
    width: 15px;
    height: 15px;
    opacity: 0.8;
  }

  100% {
    width: 200px;
    height: 200px;
    opacity: 0;
    border: 4px solid #0000ff;
  }
}

.dvEditorContainer {
  .mat-drawer-content {
    overflow: hidden auto !important;
  }

  .mat-drawer-inner-container {
    height: auto !important;
  }

  .mat-drawer-container,
  .mat-drawer-content {
    height: auto !important;
    overflow: hidden !important;
  }
}

.dvEventClassList {
  display: flex;
  flex-wrap: wrap;
  max-height: 85px;
  overflow-y: auto;
  padding-top: 2px;
  padding-bottom: 2px;
  min-width: 200px;

  span.inner {
    display: flex;
    align-items: center;
  }
}

.dvEventClassGrid {
  display: flex;
  max-width: 200px;
  overflow-x: auto;
  padding-top: 2px;
  padding-bottom: 2px;
  min-width: 200px;

  span.inner {
    white-space: nowrap;
    display: flex;
    align-items: center;
  }
}

.couponTag {
  padding: 2px 4px;
  display: flex;
  border-radius: 8px;
  align-items: center;
  max-width: fit-content;
}

.mat-icon {
  font-size: 24px;
}

@media screen and (max-width: 959px) {
  .mat-dialog-container {
    padding: 12px !important;
  }
}

.table-responsive-v15 {
  display: block;
  width: 100%;
  overflow-x: auto;

  @media screen and (max-width: 959px) {
    width: 90vw !important;
  }

  .mat-table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    display: table;
    border-collapse: collapse;
    margin: 0px;
  }

  .mat-row,
  .mat-header-row {
    display: table-row;
  }

  .mat-cell,
  .mat-header-cell {
    word-wrap: initial;
    display: table-cell;
    padding: 0px 4px;
    line-break: unset;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: middle;
  }
}

// do not remove this line it is used for to remove browser's user agent stylesheet which was blue background color
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  &,
  &:hover,
  &:focus,
  &:active {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: #31b0dd;
  -webkit-box-shadow: 0 0 0px 40rem #ffff inset;
}

.tox:not([dir="rtl"]) .tox-toolbar__group:not(:last-of-type) {
  border-right: 1px solid #ccc !important;
}

.tox[dir="rtl"] .tox-toolbar__group:not(:last-of-type) {
  border-left: 1px solid #ccc !important;
}

// Kodumun bracketi
.team-label a {
  // max-width: 129px;
  // overflow: hidden;
  display: inline-block;
  white-space: nowrap;
}

.hiddenOnDesktop {
  @media screen and (min-width: 960px) {
    display: none !important;
  }
}

.hiddenOnMobile {
  @media screen and (max-width: 959px) {
    display: none !important;
  }
}

.pill {
  border-radius: 10px;
  padding: 4px 8px;
  margin: 0 4px 4px 4px;
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
  text-align: center;
}

.btn-boxed {
  border-radius: 5px !important;
}

.btn-noRadius {
  border-radius: 0 !important;
}

.fa-spin {
  animation: fa-spin 1s infinite linear !important;
}

.introjs-hint {
  margin-top: -20px !important;
}

.rp-button {
  border: none;
  cursor: pointer;
}

.img-responsive {
	width: 100%;
    height: auto;
//   @media screen and (max-width: 599px) {
//     width: 100%;
//     height: auto;
//   }
}
.avatar-mini {
  width: 64px;
  height: 64px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  object-fit: cover;
}
.link-inherit {
  color: inherit;
  text-decoration: none;
}
.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.mat-mdc-button .mat-mdc-button-ripple {
  display: none !important;
}

.mat-mdc-tab-group .mat-mdc-tab .mdc-tab__content {
  pointer-events: all;
}
.mat-mdc-tab.mat-mdc-tab-disabled {
  opacity: 1;
}
.btn-responsive {
  @media only screen and (max-width: 1700px) {
    font-size: 16px;
    white-space: pre-wrap !important;
    min-width: 115px !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s;
  -webkit-text-fill-color: #000000 !important;
}

.tox .tox-edit-area__iframe {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.tox .tox-menu.tox-collection.tox-collection--list.tox-selected-menu {
  z-index: 100000 !important;
}

.mce-content-body {
  min-height: 80px !important;
  font-weight: normal !important;
}

.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
  color: rgba(34, 47, 62, 0.7);
  content: attr(data-mce-placeholder);
  position: absolute;
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 16px !important;
  font-weight: normal !important;
}

.mat-mdc-snack-bar-container {
  &.green-snackbar {
    --mdc-snackbar-container-color: #43a047 !important;
    --mat-snack-bar-button-color: #fff !important;
    --mdc-snackbar-supporting-text-color: #fff !important;
  }
}

.cal-month-view .cal-open-day-events {
  color: #fff;
  background-color: #adaaaa;
}
.noLinkCSS {
  text-decoration: none;
  color: inherit;
}

.cal-month-view .cal-open-day-events {
  color: #000;
  background-color: #fff;
  box-shadow: inset 0 0 10px 0 rgb(32 157 241 / 50%);
}

.btn-larger {
  height: 48px !important;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 18px;
  padding-right: 18px;
}

// .ng-valid[required], .ng-valid.required  {
//   border-left: 8px solid #42A948; __<<ngM2ThemingMigrationEscapedComment26>>__
// }

// .ng-invalid:not(form)  {
//   border-left: 8px solid #a94442; __<<ngM2ThemingMigrationEscapedComment27>>__
// }

// Mobile visibility stuff

.onMobile {
  .hiddenOnMobile {
    display: none !important;
  }
}
.onDesktop {
  .hiddenOnDesktop {
    display: none !important;
  }
}

.cal-week-view .cal-event {
  color: #222 !important;
}
.mat-mdc-no-data-row {
  min-height: 40px;
  height: 40px;
  max-height: 40px;
}
.link_wordBreak {
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.responsiveForm {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;

  @media screen and (max-width: 599px) {
    flex-direction: column;
    justify-content: center;
    gap: 0px;
  }
}

.border-separate {
  border-collapse: separate;
}

.mat-mdc-table-sticky-border-elem-top {
  border-bottom: 2px solid #cdcdcd;
}

.mat-mdc-table-sticky-border-elem-right {
  border-left: 2px solid #cdcdcd;
}

.mat-mdc-table-sticky-border-elem-bottom {
  border-top: 2px solid #cdcdcd;
}

.mat-mdc-table-sticky-border-elem-left {
  border-right: 2px solid #cdcdcd;
}

.p-2 {
  padding: 2px;
}

.w-170px {
  min-width: 170px;
  width: 170px;
  max-width: 170px;
}

.w-200px {
  min-width: 200px;
  width: 200px;
  max-width: 200px;
}

/* Tinymce image/file/media loading indicator */
.tinymce-loading-spinner {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  background: rgba(255, 255, 255, 0.8) !important;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tiny-roller,
.tiny-roller div,
.tiny-roller div:after {
  box-sizing: border-box;
}
.tiny-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.tiny-roller div {
  animation: tiny-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.tiny-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7.2px;
  height: 7.2px;
  border-radius: 50%;
  background: currentColor;
  margin: -3.6px 0 0 -3.6px;
}
.tiny-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.tiny-roller div:nth-child(1):after {
  top: 62.62742px;
  left: 62.62742px;
}
.tiny-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.tiny-roller div:nth-child(2):after {
  top: 67.71281px;
  left: 56px;
}
.tiny-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.tiny-roller div:nth-child(3):after {
  top: 70.90963px;
  left: 48.28221px;
}
.tiny-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.tiny-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.tiny-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.tiny-roller div:nth-child(5):after {
  top: 70.90963px;
  left: 31.71779px;
}
.tiny-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.tiny-roller div:nth-child(6):after {
  top: 67.71281px;
  left: 24px;
}
.tiny-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.tiny-roller div:nth-child(7):after {
  top: 62.62742px;
  left: 17.37258px;
}
.tiny-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.tiny-roller div:nth-child(8):after {
  top: 56px;
  left: 12.28719px;
}
@keyframes tiny-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

gallery-slider {
  background-color: #f7f7f7;
}
.g-slider {
  background: #f7f7f7;
}

.g-dot-inner {
  border: 2px solid #000;
}

gallery-thumb img {
  background-color: #f7f7f7;
}

.noUnderline {
  text-decoration: none;
}

.card-responsive {
  display: flex;
  flex-direction: row;

  //fxFlex.xs
  @media screen and (max-width: 599px) {
    //not growable (0), shrinkable (1), and with an initial length of 50% and a maximum length of 50% minus 10px
    flex: 0 1 calc(50% - 10px);
  }

  //fxFlex.sm
  @media screen and (min-width: 600px) and (max-width: 959px) {
    flex: 0 1 calc(50% - 10px);
  }

  //fxFlex.md
  @media screen and (min-width: 960px) and (max-width: 1279px) {
    flex: 0 1 calc(33% - 10px);
  }

  //fxFlex.gt-md
  @media screen and (min-width: 1280px) {
    flex: 0 1 calc(25% - 10px);
  }
}

.hide-sm {
  @media screen and (max-width: 599px) {
    display: none !important;
  }
}

.show-sm {
  @media screen and (max-width: 599px) {
    display: initial;
  }
}

.show-lt-md {
  @media screen and (max-width: 959px) {
    display: initial;
  }
}

.hide-md {
  @media screen and (min-width: 960px) and (max-width: 1279px) {
    display: none !important;
  }
}

.hide-lg {
  @media screen and (min-width: 1280px) and (max-width: 1599px) {
    display: none !important;
  }
}

.hide-xl {
  @media screen and (min-width: 1920px) {
    display: none !important;
  }
}

.show-md {
  @media screen and (min-width: 960px) and (max-width: 1279px) {
    display: initial;
  }
}

///////////////FLEX CLASSES////////////////////

.flex-1 {
  flex: 1;
} // Same as flex: 1 1 0 (grow, shrink, basis 0). Has similar effect to width: 100%;
.flex-grow {
  flex-grow: 1;
} // Same as flex: 1 1 auto (grow, shrink, basis auto). For spacer, etc.

// Flex display
.flex {
  display: flex;
  box-sizing: border-box;
}
.flex-align-center {
  display: flex;
  align-items: center;
}
.inline-flex {
  display: inline-flex;
  box-sizing: border-box;
}

// Flex direction
// Flex row/col + grid. <div fxLayout="row"> → <div class="flex-row">
.flex-row {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}
.flex-col {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.grid {
  display: grid;
}

// Flex-wrap utils.
.flex-wrap {
  flex-wrap: wrap;
} // Mostly used with flex-row, when wrapping is desired.

// Switch from flex-row to flex-col on mobile.
.flex-col-xs {
  @media screen and (max-width: 599px) {
    flex-direction: column;
  }
}

.flex-col-sm {
  @media screen and (max-width: 959px) {
    flex-direction: column;
  }
}

.flex-col-md {
  @media screen and (max-width: 1279px) {
    flex-direction: column;
  }
}

.flex-row-xs {
  @media screen and (max-width: 599px) {
    flex-direction: row;
  }
}

.flex-row-sm {
  @media screen and (max-width: 959px) {
    flex-direction: row;
  }
}

.flex-row-md {
  @media screen and (max-width: 1279px) {
    flex-direction: row;
  }
}

// Shorthands

.flex-initial {
  flex: initial;
} // 0 1 auto;
.flex-auto {
  flex: auto;
} // 1 1 auto;

// https://www.w3.org/TR/css-flexbox-1/images/rel-vs-abs-flex.svg
// fxFlex <div fxFlex> → <div class="flex-1">
// Flex/grow/shrink properties https://developer.mozilla.org/en-US/docs/Web/CSS/flex.

.flex-grow {
  flex-grow: 1;
} // Same as flex: 1 1 auto (grow, shrink, basis auto). For spacer, etc.
.flex-1 {
  flex: 1;
} //flex: 1 1 0 i.e. fxFlex Same as flex: 1 1 0 (grow, shrink, basis 0). Has similar effect to width: 100%;
.flex-2 {
  flex: 2;
} //flex: 2 1 0
.flex-3 {
  flex: 3;
} //flex: 3 1 0
.flex-4 {
  flex: 4;
} //flex: 4 1 0
.flex-5 {
  flex: 5;
} //flex: 5 1 0
.flex-none {
  flex: none;
} // 0 0 auto
.flex-nogrow {
  flex: 0 1 auto;
}
.flex-noshrink {
  flex: 1 0 auto;
}
.flex-grow {
  flex: 1 1 100%;
}
.flex-grow25 {
  flex: 0 1 25%;
}
.flex-grow50 {
  flex: 0 1 50%;
}
.flex-grow75 {
  flex: 0 1 75%;
}

//Justify-Content
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.space-between {
  justify-content: space-between;
} // Used very often with flex-row.
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}

//Align-Content
.content-start {
  align-content: flex-start;
}
.content-end {
  align-content: flex-end;
}
.content-center {
  align-content: center;
}
.content-stretch {
  align-content: stretch;
}
.content-between {
  align-content: space-between;
}
.content-around {
  align-content: space-around;
}
.content-evenly {
  align-content: space-evenly;
}

//Align-Items
// Align items. (Naming inspiration: https://tailwindcss.com/docs/align-items.)
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.align-center {
  align-items: center;
}
.align-stretch {
  align-items: stretch;
}
.align-baseline {
  align-items: baseline;
}
.center-center {
  justify-content: center;
  align-items: center;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.items-stretch {
  align-items: stretch;
}
.items-baseline {
  align-items: baseline;
}

//Align-Self
.item-start {
  align-self: flex-start;
}
.item-end {
  align-self: flex-end;
}
.item-center {
  align-self: center;
}
.item-stretch {
  align-self: stretch;
}
.item-baseline {
  align-self: baseline;
}

// fxLayoutGap <div fxLayoutGap="8px"> → <div class="gap-8">
.gap-4 {
  gap: 4px;
}
.gap-5 {
  gap: 5px;
}
.gap-8 {
  gap: 8px;
}
.gap-10 {
  gap: 10px;
}
.gap-12 {
  gap: 12px;
}
.gap-16 {
  gap: 16px;
}
.gap-20 {
  gap: 20px;
}

//Item Utilities
center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.fill {
  margin: 0;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
}

.center-text {
  text-align: center;
}
// Hide & show for different breakpoints.
.hide {
  display: none;
}
.hide-xs {
  @media screen and (max-width: 599px) {
    display: none;
  }
} // Hide on mobile.
.hide-gt-xs {
  @media screen and (min-width: 600px) {
    display: none;
  }
} // Show only on mobile. Hide on desktop.
.hide-sm {
  @media screen and (max-width: 959px) {
    display: none;
  }
} // Hide on mobile/tablet.
.hide-gt-sm {
  @media screen and (min-width: 960px) {
    display: none;
  }
} // Show only on mobile/tablet. Hide on desktop.

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-end-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex-justify-center,
.flex-justify {
  display: flex;
  @media screen and (max-width: 599px) {
    flex-direction: column;
    justify-content: flex-start;
  }
  @media screen and (min-width: 600px) {
    justify-content: space-between;
    align-items: center;
  }
}
.flex-evenly-center {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.flex-wrap {
  flex-wrap: wrap;
}

.dayView4Fields .cal-header {
  white-space: break-spaces !important;
}

.game4score mwl-calendar-event-title div {
  white-space: pre-line;
  line-height: 16px;
  text-align: center;

  @media screen and (max-width: 599px) {
	line-height: 11px;
  }
}

mat-mdc-menu-item:not([disabled]):hover {
  background-color: unset !important;
}

.bigPictureCalendars .cal-week-view .cal-event {
  border: 3px solid;
}

.bp-dw {
	.cal-event {
    	color: #ffffff !important;
  	}

}



.dayView4Fields {

  .cal-time-events {
    display: flex;
    position: relative; /* Ensure it contains the sticky positioning context */
  }

  .sticky-column {
    position: sticky;
    left: 0;
    z-index: 2; /* Ensure it stays on top of other content */
    background: white; /* Match the background to the container */
    box-shadow: 2px 0 5px -2px rgba(0, 0, 0, 0.2); /* Optional: Add a shadow for visual separation */
  }

  .cal-hour-odd {
    background-color: #f9f9f9; /* Example background color for odd hours */
  }

  .cal-day-columns {
    display: flex;
    flex: 1;
  }

  .cal-day-headers {
    display: flex;
    position: relative;
  }

  .cal-day-header {
    min-width: 150px;
    padding: 10px;
    text-align: center;
  }

  .cal-day-header.sticky {
    position: sticky;
    left: 0;
    z-index: 3;
    background: white;
    box-shadow: 2px 0 5px -2px rgba(0, 0, 0, 0.2);
  }

  .header-padding {
    width: 70px; /* Adjust to match the padding-left of .cal-day-headers */
    flex-shrink: 0;
  }

  .cal-day-view .cal-current-time-marker {
    margin-left: 0px;
    width: 100%;
  }
}

//as google play store for mobile considers the labels as a low contrast
// .mat-form-field-label {
//   color: rgba(0, 0, 0, 0.87) !important; // Adjust this value to increase contrast
// }

// .mat-checkbox-label {
//   color: rgba(0, 0, 0, 0.87) !important; // Ensure high contrast for checkbox labels
// }

.mat-form-field-label {
  color: #000000 !important; /* Adjust the color for maximum contrast */
}

.mat-form-field-underline {
  background-color: #000000 !important;
}

.mat-form-field-ripple {
  background-color: #000000 !important;
}

mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgb(0 0 0) !important;
}

.touch-target {
  @media screen and (max-width: 599px) {
    min-width: 48px;
    min-height: 48px;
    display: inline !important;
    font-size: 16px;
    padding: 12px;
  }
}
.mce-accordion {
  padding: 8px !important;
}

.tab-container  .mat-mdc-tab-header .mdc-tab {
    min-width: fit-content;
    padding: 0 8px;
    background: #fff;
    margin-right: 2px;
  }

.tab-container  .mdc-tab--active {
    border-color: #dee2e6 #dee2e6 #fff !important;
    border: 1px solid #0000001f !important;
	background-color: #f5f5f5 !important;
}

.no-tab-paging .mat-mdc-tab-labels {
  flex-wrap: wrap;
}

.mat-mdc-tab-header-pagination-controls-enabled .mat-mdc-tab-header-pagination {
    display: flex;
}