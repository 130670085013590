@mixin fuse-search-bar-theme($theme) {

    $background: map-get($theme, background);
    $accent: map-get($theme, accent);

    .fuse-search-bar {
        mat-icon {
			color: map-get($accent, default) !important;
		}

        &.expanded {
            background-color: map-get($background, background);
            
        }
    }
}