@mixin event-form-theme($theme) {
  $primary: map-get($theme, primary);

  .rp-timepicker-class {
    .timepicker__header{
      background-color: map-get($primary, default);
    }

    .clock-face__clock-hand {
      background-color: map-get($primary, default) !important;

      &::after {
        background-color: map-get($primary, default) !important;
      }

      &::before {
        border-color: map-get($primary, default) !important;
      }
    }

    .clock-face__number {
      >span.active {
        background-color: map-get($primary, default) !important;
      }
    }
  }
}
