 //@use primarily handles mixins, functions, and variables.
 // like: $primary-color: #3498db;  or --toolbar-background: white;

//@debug 'Loading _flex-layout.scss';



/////////////// FLEX MIXINS ////////////////////
/* Breakpoints */

$xs: 599px;
$sm: 959px;
$md: 1279px;
$lg: 1919px;
$xl: 5000px;

$fx-media-queries: (
  'xs': "screen and (max-width: #{$xs})",
  'sm': "screen and (min-width: #{$xs + 1}) and (max-width: #{$sm})",
  "md": "screen and (min-width: #{$sm + 1}) and (max-width: #{$md})",
  "lg": 'screen and (min-width: #{$md + 1}) and (max-width: #{$lg})',
  "xl": 'screen and (min-width: #{$lg + 1}) and (max-width: #{$xl})',
  "lt-sm": 'screen and (max-width: #{$xs})',
  "lt-md": 'screen and (max-width: #{$sm})',
  "lt-lg": 'screen and (max-width: #{$md})',
  "lt-xl": 'screen and (max-width: #{$lg})',
  "gt-xs": 'screen and (min-width: #{$xs + 1})',
  "gt-sm": 'screen and (min-width: #{$sm + 1})',
  "gt-md": 'screen and (min-width: #{$md + 1})',
  "gt-lg": 'screen and (min-width: #{$lg + 1})',
);

@mixin breakpoint($bp) {
  @media #{map-get($fx-media-queries, $bp)} {
    @content;
  }
}

@mixin retina {
  @media
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    @content;
  }
}

@mixin only-for-screen-sizes($sizes...) {
  @if (length($sizes) > 0) {
    $selectors: ();
    @each $size in $sizes {
      $selectors: join(
        $selectors,
        unquote(":host-context(.#{$size}) & "),
        comma
      );
    }
    #{$selectors} {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin fx-layout($direction, $sizes...) {
  @include only-for-screen-sizes($sizes...) {
    display: flex;
    flex-direction: $direction;
  }
}

@mixin fx-layout-with-gap($direction, $value, $sizes...) {
  @include fx-layout($direction, $sizes...);
  @include only-for-screen-sizes($sizes...) {
    @if ($direction == row) {
      > * {
        margin-right: $value;
      }
      > *:last-child {
        margin-right: 0;
      }
    } @else if ($direction == column) {
      > * {
        margin-bottom: $value;
      }
      > *:last-child {
        margin-bottom: 0;
      }
    } @else if ($direction == row-reverse) {
      > * {
        margin-left: $value;
      }
      > *:last-child {
        margin-left: 0;
      }
    } @else if ($direction == reverse-column) {
      > * {
        margin-top: $value;
      }
      > *:last-child {
        margin-top: 0;
      }
    }
  }
}

@mixin fx-flex($grow, $shrink, $basis, $sizes...) {
  @include only-for-screen-sizes($sizes...) {
    flex-basis: $basis;
    flex-grow: $grow;
    flex-shrink: $shrink;
  }
}

@mixin fx-fill($sizes...) {
  @include only-for-screen-sizes($sizes...) {
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;
  }
}

@mixin fx-layout-alignment(
  $main-axis-alignment,
  $cross-axis-alignment,
  $sizes...
) {
  @include only-for-screen-sizes($sizes...) {
    justify-content: $main-axis-alignment;
    align-items: $cross-axis-alignment;
    align-content: $cross-axis-alignment;
  }
}


@mixin print {  @media print {   @content;  }}
@mixin flex-center { display: flex; justify-content: center; align-items: center;}
@mixin flex-1 { flex: 1 1 0%; } // Same as flex: 1 1 0 (grow, shrink, basis 0). Has similar effect to width: 100%;
// flex: 1 means the following:
// flex-grow : 1;    ➜ The div will grow in same proportion as the window-size
// flex-shrink : 1;  ➜ The div will shrink in same proportion as the window-size
// flex-basis : 0;   ➜ The div does not have a starting value as such and will take up screen as per the screen size available for e.g:- if 3 divs are in the wrapper then each div will take 33%.
@mixin flex-grow { flex-grow: 1; } // Same as flex: 1 1 auto (grow, shrink, basis auto). For spacer, etc.
@mixin flex {display: flex;  box-sizing: border-box;}
@mixin flex-align-center { display: flex; align-items: center;}
@mixin inline-flex { display: inline-flex;  box-sizing: border-box;}
// Flex direction Flex row/col + grid. <div fxLayout="row"> → <div class="flex-row">
@mixin flex-row { display: flex; flex-direction: row; box-sizing: border-box;}
@mixin flex-col { display: flex; flex-direction: column; box-sizing: border-box;}
@mixin grid { display: grid;}
@mixin flex-wrap { flex-wrap: wrap;}
// Switch from flex-row to flex-col on mobile.
@mixin flex-col-xs {
  @media screen and (max-width: 599px) {
    flex-direction: column;
  }
}
@mixin flex-col-sm {
  @media screen and (max-width: 959px) {
    flex-direction: column;
  }
}

@mixin flex-col-md {
  @media screen and (max-width: 1279px) {
    flex-direction: column;
  }
}

@mixin flex-row-xs {
  @media screen and (max-width: 599px) {
    flex-direction: row;
  }
}

@mixin flex-row-sm {
  @media screen and (max-width: 959px) {
    flex-direction: row;
  }
}

@mixin flex-row-md {
  @media screen and (max-width: 1279px) {
    flex-direction: row;
  }
}

@mixin flex-initial {
  flex: initial;
}

@mixin flex-auto {
  flex: auto;
}

// https://www.w3.org/TR/css-flexbox-1/images/rel-vs-abs-flex.svg
// fxFlex <div fxFlex> → <div class="flex-1">
// Flex/grow/shrink properties https://developer.mozilla.org/en-US/docs/Web/CSS/flex.
@mixin flex-2 { flex: 2 1 0; }
@mixin flex-3 { flex: 3 1 0; }
@mixin flex-4 { flex: 4 1 0; }
@mixin flex-5 { flex: 5 1 0; }
@mixin flex-none { flex: none;}
@mixin flex-nogrow { flex: 0 1 auto;}
@mixin flex-noshrink { flex: 1 0 auto;}
@mixin flex-grow100 { flex: 1 1 100%;}
@mixin flex-grow25 { flex: 0 1 25%;}
@mixin flex-grow50 { flex: 0 1 50%;}
@mixin flex-grow75 { flex: 0 1 75%;}

//Justify-Content
@mixin justify-start { justify-content: flex-start;}
@mixin justify-end { justify-content: flex-end;}
@mixin justify-center { justify-content: center;}
@mixin justify-between { justify-content: space-between;}
@mixin justify-around { justify-content: space-around;}
@mixin justify-evenly { justify-content: space-evenly;}



//Align-Items
@mixin align-start { align-items: flex-start;}
@mixin align-end { align-items: flex-end;}
@mixin align-center { align-items: center;}
@mixin align-stretch { align-items: stretch;}
@mixin align-baseline { align-items: baseline;}
@mixin center-center { justify-content: center; align-items: center;}
@mixin items-start { align-items: flex-start;}
@mixin items-end { align-items: flex-end;}
@mixin items-center { align-items: center;}
@mixin items-stretch { align-items: stretch;}
@mixin items-baseline { align-items: baseline;}

//Align-Self
@mixin item-start { align-self: flex-start;}
@mixin item-end { align-self: flex-end;}
@mixin item-center { align-self: center;}
@mixin item-stretch { align-self: stretch;}
@mixin item-baseline { align-self: baseline;}

// fxLayoutGap <div fxLayoutGap="8px"> → <div class="gap-8">
@mixin gap-4 { gap: 4px;}
@mixin gap-5 { gap: 5px;}
@mixin gap-8 { gap: 8px;}
@mixin gap-10 { gap: 10px;}
@mixin gap-12 { gap: 12px;}
@mixin gap-16 { gap: 16px;}
@mixin gap-20 { gap: 20px;}

//Item Utilities
@mixin center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@mixin fill {
  margin: 0;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
}

@mixin center-text { text-align: center;}
@mixin hide { display: none;}
// Hide on mobile.
@mixin hide-xs {
  @media screen and (max-width: 599px) {
    display: none;
  }
}
// Show only on mobile. Hide on desktop.
@mixin hide-gt-xs {
  @media screen and (min-width: 600px) {
    display: none;
  }
}
// Hide on mobile/tablet.
@mixin hide-sm {
  @media screen and (max-width: 959px) {
    display: none;
  }
}
// Show only on mobile/tablet. Hide on desktop.
@mixin hide-gt-sm {
  @media screen and (min-width: 960px) {
    display: none;
  }
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@mixin flex-end-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@mixin flex-justify {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-evenly-center {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

@mixin flex-wrap {
  flex-wrap: wrap;
}



//############################################################################################################
// Note for below css : fxFlex is able to add display: flex on a parent that doesn't have an fxLayout directive.
// fxLayoutAlign can also add display: flex on its host element even without fxLayout.
// This should be taken into account
//############################################################################################################

// [fxLayout] {
// 	display: flex;
// 	box-sizing: border-box;
//   }
//   [fxLayout=row] {
// 	flex-direction: row;
//   }
//   [fxLayout='row wrap'] {
// 	flex-direction: row;
// 	flex-wrap: wrap;
//   }
//   [fxLayout=column] {
// 	flex-direction: column;
//   }
//   [fxLayout='column wrap'] {
// 	flex-direction: column;
// 	flex-wrap: wrap;
//   }

//   [fxFlex] {
// 	flex: 1 1 auto;
//   }

//   [fxLayoutAlign='start center'] {
// 	justify-content: flex-start;
// 	align-items: center;
//   }
//   [fxLayoutAlign='start stretch'] {
// 	justify-content: flex-start;
// 	align-items: stretch;
//   }
//   [fxLayoutAlign='center start'] {
// 	justify-content: center;
// 	align-items: flex-start;
//   }
//   [fxLayoutAlign='space-between center'] {
// 	justify-content: space-between;
// 	align-items: center;
//   }
//   [fxLayoutAlign='stretch stretch'] {
// 	align-content: stretch;
// 	align-items: stretch;
//   }
//   [fxLayoutAlign='stretch center'] {
// 	align-content: stretch;
// 	align-items: center;
//   }

//   [fxLayoutGap='1rem'] {
// 	column-gap: 1rem;
//   }
//   [fxLayoutGap='1rem grid'] {
// 	gap: 1rem;
//   }
//############################################################################################################

// fxFlex
[fxFlex] {
    flex: 1 1 0%;
    box-sizing: border-box;
}

*:has(> [fxFlex]) {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
}

[fxFlex='5'] {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 5%;
}

[fxFlex='20'] {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 20%;
}

[fxFlex='25'] {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 25%;
}

[fxFlex='30'] {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 30%;
}

[fxFlex='33'] {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 33%;
}

[fxFlex='50'] {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 50%;
}

[fxFlex='66'] {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 66%;
}

[fxFlex='67'] {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 67%;
}

[fxFlex='70'] {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 70%;
}

[fxFlex='80'] {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 80%;
}

[fxFlex='100'] {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 100%;
}

[fxFlex='1 0 auto'] {
    flex: 1 0 auto;
    box-sizing: border-box;
}

[fxFlex='0 1 auto'] {
    flex: 0 1 auto;
    box-sizing: border-box;
}

// fxLayout
[fxLayout] {
    box-sizing: border-box;
    display: flex !important;
}

[fxLayout='row wrap'] {
    flex-flow: row wrap;
    flex: 1 1 1e-9px;
}

[fxLayout='row'] {
    flex-direction: row;
}

[fxLayout='column'] {
    flex-direction: column !important;

    &[fxLayoutGap='8px'] > *:not(:last-child) {
        margin-right: unset;
        margin-bottom: 8px;
    }
    &[fxLayoutGap='12px'] > *:not(:last-child) {
        margin-right: unset;
        margin-bottom: 12px;
    }
    &[fxLayoutGap='16px'] > *:not(:last-child) {
        margin-right: unset;
        margin-bottom: 16px;
    }
    &[fxLayoutGap='24px'] > *:not(:last-child) {
        margin-right: unset;
        margin-bottom: 24px;
    }
    &[fxLayoutGap='32px'] > *:not(:last-child) {
        margin-right: unset;
        margin-bottom: 32px;
    }

    & > [fxFlex='5'] {
        max-height: 5%;
        max-width: unset;
    }
    & > [fxFlex='20'] {
        max-height: 20%;
        max-width: unset;
    }
    & > [fxFlex='25'] {
        max-height: 25%;
        max-width: unset;
    }
    & > [fxFlex='30'] {
        max-height: 30%;
        max-width: unset;
    }
    & > [fxFlex='33'] {
        max-height: 33%;
        max-width: unset;
    }
    & > [fxFlex='50'] {
        max-height: 50%;
        max-width: unset;
    }
    & > [fxFlex='66'] {
        max-height: 66%;
        max-width: unset;
    }
    & > [fxFlex='67'] {
        max-height: 67%;
        max-width: unset;
    }
    & > [fxFlex='70'] {
        max-height: 70%;
        max-width: unset;
    }
    & > [fxFlex='80'] {
        max-height: 80%;
        max-width: unset;
    }
    & > [fxFlex='100'] {
        max-height: 100%;
        max-width: unset;
    }
}

// fxLayoutGap
[fxLayoutGap='8px'] > *:not(:last-child) {
    margin-right: 8px;
}
[fxLayoutGap='12px'] > *:not(:last-child) {
    margin-right: 12px;
}
[fxLayoutGap='16px'] > *:not(:last-child) {
    margin-right: 16px;
}
[fxLayoutGap='24px'] > *:not(:last-child) {
    margin-right: 24px;
}
[fxLayoutGap='32px'] > *:not(:last-child) {
    margin-right: 32px;
}

// fxLayoutAlign
[fxLayoutAlign] {
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
}
[fxLayoutAlign='center center'] {
    place-content: center;
    align-items: center;
}
[fxLayoutAlign='end center'] {
    place-content: center flex-end;
    align-items: center;
}
[fxLayoutAlign='end top'] {
    place-content: stretch flex-end;
    align-items: stretch;
}
[fxLayoutAlign='start center'] {
    place-content: center flex-start;
    align-items: center;
}
[fxLayoutAlign='start start'] {
    place-content: flex-start;
    align-items: flex-start;
}
[fxLayoutAlign='space-between center'] {
    place-content: center space-between;
    align-items: center;
}
[fxLayoutAlign='start stretch'] {
    place-content: stretch flex-start;
    align-items: stretch;
    max-height: 100%;
}
[fxLayoutAlign='end'] {
    place-content: stretch flex-end;
    align-items: stretch;
}
[fxLayoutAlign='center stretch'] {
    place-content: stretch center;
    align-items: stretch;
    max-height: 100%;
}
[fxLayoutAlign='center center'] {
    place-content: center;
    align-items: center;
}
[fxLayoutAlign='center end'] {
    place-content: flex-end center;
    align-items: flex-end;
}
[fxLayoutAlign='stretch stretch'] {
    place-content: stretch flex-start;
    align-items: stretch !important;
}

// fxHide
[fxHide] { display: none;}



// `.` can't be part of attribute selector :(
// fxHide.lt-lg in other word The use of square brackets in your CSS selector is a way to select HTML elements based on their attributes.
// like <div fxHide.lt-lg> → <div class="fx-hide-lt-lg">

// fxHide.gt-lg
@media screen and (max-width: 1279px) {
    [fxHidelt-lg] {
        display: none !important;
    }
}

// fxHide.gt-md
@media screen and (min-width: 1280px) {
    [fxHidegt-md] {
        display: none !important;
    }
}

// fxHide.gt-sm
@media screen and (min-width: 960px) {
    [fxHidegt-sm] {
        display: none !important;
    }
}

// fxHide.lt-md
@media screen and (max-width: 959px) {
    [fxHidelt-md] {
        display: none !important;
    }
}

// fxHide.xs
@media screen and (max-width: 599px) {
    [fxHide-xs] {
        display: none;
    }
}

// fxShow.gt-sm
@media screen and (min-width: 960px) {
    [fxShowgt-sm] {
        display: initial;
    }
}

// fxShow.gt-md
@media screen and (min-width: 1280px) {
    [fxShowgt-md] {
        display: initial;
    }
}

// fxShow.gt-xs
@media screen and (min-width: 600px) {
    [fxShowgt-xs] {
        display: initial;
    }
}

@media (min-width: 599px) {
  .fx-show-gt-xs {
    display: initial !important;
  }
}

// fxShow.lt-md
@media screen and (max-width: 959px) {
    [fxShowlt-md] {
        display: initial;
    }
}




// fxFill
[fxFill] {
    height: 100%;
    min-height: 100%;
    min-width: 100%;
    width: 100%;
}

@media (max-width: 959px) {
  .fx-hide-lt-md {
    display: none !important;
  }
}

@media (max-width: 959px) {
  .fx-flex-lt-md-15 {
    flex: 1 1 15%;
  }
}

