@use "sass:map";
@use "@angular/material" as mat;
@include mat.core();


$color-black: #000;
$color-gray:#444;
$color-green: #00D977;
$color-orange: #EF693A;
$color-white: #FFF;
$color-usasb1:#1a3668;
$color-usasb2:#d42131;

$foreground-palette: mat.m2-define-palette(mat.$m2-grey-palette, 50, 50, 50);
$background-palette: mat.m2-define-palette(mat.$m2-grey-palette, 50, 50, 50);

$ribbon-colors: (
	backgroundColor: #3d5a8e,
	borderLeftColor: #bdbbbc,
	textColor: #fff
);

$ribbon-colors-reverse: (
	backgroundColor: #bdbbbc,
	borderLeftColor: #3d5a8e,
	textColor: #000
);

$ribbon-buttons: (
	backgroundColor: #928f90,
	textColor: #fff 
);

$progress-bar-buffer: (
	background-color: #748bb3
);

$messageBox: (
	backgroundColor: #1a3668,
	borderLeftColor: #d42131,
	textColor: #fff
);

$cms: (
	backgroundColor: $color-usasb1,
	textColor: #fff
);

.username{
	color: map.get($foreground-palette, default-contrast);
}

//.username{ color: map-get($foreground-palette, text);}

$custom-primary:(
	50: #e0e0e0,
	100: #b3b3b3,
	200: #808080,
	300: #4d4d4d,
	400: #262626,
	500: #000000,
	600: #000000,
	700: #000000,
	800: #000000,
	900: #000000,
	A100: #a6a6a6,
	A200: #8c8c8c,
	A400: #737373,
	A700: #666666,
	contrast: (
		50: rgba(black, 0.87),
		100: rgba(black, 0.87),
		200: rgba(black, 0.87),
		300: #fff,
		400: #fff,
		500: #fff,
		600: #fff,
		700: #fff,
		800: #fff,
		900: #fff,
		A100: rgba(black, 0.87),
		A200: rgba(black, 0.87),
		A400: #fff,
		A700: #fff,
	)
);

$custom-accent:(
	50: #e1edf6,
	100: #b3d2e8,
	200: #80b4d9,
	300: #4d96ca,
	400: #2780be,
	500: #0169b3,
	600: #0161ac,
	700: #0156a3,
	800: #014c9a,
	900: #003b8b,
	A100: #b7d0ff,
	A200: #84aeff,
	A400: #518dff,
	A700: #377cff,
	contrast: (
			50: rgba(black, 0.87),
			100: rgba(black, 0.87),
			200: rgba(black, 0.87),
			300: rgba(black, 0.87),
			400: #fff,
			500: #fff,
			600: #fff,
			700: #fff,
			800: #fff,
			900: #fff,
			A100: rgba(black, 0.87),
			A200: rgba(black, 0.87),
			A400: rgba(black, 0.87),
			A700: #fff,
	)
);

$custom-warn: (
	50: #f7f7f7,
	100: #ebebeb,
	200: #deddde,
	300: #d1cfd0,
	400: #c7c5c6,
	500: #bdbbbc,
	600: #b7b5b6,
	700: #aeacad,
	800: #a6a4a5,
	900: #989697,
	A100: #cdd1d4,
	A200: #ffffff,
	A400: #ffd2e1,
	A700: #6C6C70,
	contrast: (
		50: rgba(black, 0.87),
		100: rgba(black, 0.87),
		200: rgba(black, 0.87),
		300: #fff,
		400: #fff,
		500: #fff,
		600: #fff,
		700: #fff,
		800: #fff,
		900: #fff,
		A100: rgba(black, 0.87),
		A200: #fff,
		A400: #fff,
		A700: #fff,
	)
);






