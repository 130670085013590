//@debug 'Loading _style-default.scss';

$primary-color: #3498db;
$secondary-color: #2ecc71;
$spacing-unit: 16px;

:root {
// Toolbar
  --toolbar-background: white;
  --toolbar-color: #5c77ff;
  --toolbar-icon-color: #5c77ff;

  --background-app-bar: rgb(235, 235, 238);
  --background-card: #fff;
  --background-hover: rgba(0, 0, 0, 0.04);
  --border-radius: 4px;
  --default-icon-size: 24px;
  --text-color: rgba(0, 0, 0, 0.87);
  --text-secondary: rgba(0, 0, 0, 0.54);
  --foreground-divider : rgba(82, 63, 105, 0.06);
  --padding: 1.5rem;
}



