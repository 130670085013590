@import "./variable.scss";

@mixin player-theme($theme) {
	$primary: map-get($theme, primary);
	$accent: map-get($theme, accent);
	$lineColor: map-get($primary, default);
	$headerColor: map-get($primary, default);
	$color: #666;
	$breakpoint: 700px;

	// .timeline {
	// 	&:before {
	// 		border: 1px solid $lineColor;
	// 	}
	// }



	.timeline {
		--uiTimelineMainColor: var(--timelineMainColor, $primary);
		--uiTimelineSecondaryColor: var(--timelineSecondaryColor, #fff);
	}

	.timeline__card::before {
		background-color: var(--timelineMainColor);
	}
	
	.timeline__card.released::before {
		background-color: #999;
	}


	.timeline__item {
		border: 2px solid $lineColor;
		border-image: linear-gradient(to right, $color 0%, $lineColor 100%);

		&:before {
			border-color: $lineColor $lineColor transparent transparent;
		}

		@media screen and (min-width: $breakpoint) {
			&:nth-of-type(2n) {
				border-image: linear-gradient(to right,
						$lineColor 0%,
						$color 100%);

				&:before {
					border-color: transparent transparent $lineColor $lineColor;
				}
			}
		}
	}

	.timeline__item--year {
		background-color: $lineColor;
	}

	#mtDtimeline.vertical {
		width: 100%;

		// Gradient
		//background: -webkit-gradient(linear, left top, left bottom, from(#1D2671), to(#C33764)) fixed;

		//background-image: url(/assets/images/dirt_bg.jpg);

		//background: linear-gradient( 180deg, white 0%, #a7aeb9b8 52%, rgba(26, 54, 104, 0.3491771709) 100%, #c5cede 100%);
		box-shadow: 5px 5px 10px rgb(0 0 0 / 50%);
		color: $color-black;
		//position: relative;

		&::before {
			top: 60px;
			bottom: 0;
			position: absolute;
			content: " ";
			width: 2px;
			background-color: $headerColor;

			left: 50%;
			// height: 100%;
			margin-left: -1.5px;
		}

		nav {
			height: 50px;
			display: table;
			margin: 0 auto;

			a {
				display: inline;
				position: relative;
				margin: 0px;
				padding: 0px;
				text-decoration: none;
				font-size: 16px;
				padding: 10px;
				padding-top: 20px;
				color: $color-white;
				font-weight: bold;

				&:hover {
					color: $headerColor;
					border-bottom: 3px solid $headerColor;
					font-weight: normal;
				}
			}
		}

		section {
			position: relative;
			display: table;
			width: 100%;

			header[class*="group"] {
				width: 65px;
				font-size: 1.3em;
				margin: 35px auto;
				padding: 5px;
				//border-radius: 0px;
				//   background: $color-orange;
				//background: $headerColor;
				//color: $color-white;
				//display: block;
				text-align: center;
				cursor: initial;
				font-weight: bold;
				// -moz-box-shadow: 0 0 0 2px $headerColor,
				// 	0 0 2px 0 rgba(0, 0, 0, 0.1),
				// 	0 3px 0 2px rgba(0, 0, 0, 0.15);
				// -webkit-box-shadow: 0 0 0 2px $headerColor,
				// 	0 0 2px 0 rgba(0, 0, 0, 0.1),
				// 	0 3px 0 2px rgba(0, 0, 0, 0.15);
				// box-shadow: 0 0 0 2px $headerColor, 0 0 2px 0 rgba(0, 0, 0, 0.1),
				// 	0 3px 0 2px rgba(0, 0, 0, 0.15);
			}

			.item {
				width: 100%;
				margin-bottom: 20px;

				&::after {
					content: "";
					display: block;
					clear: both;
				}

				article {
					position: relative;
					width: 45%;
					background: $color-white;
					border: none;
					-webkit-border-radius: 0px;
					-moz-border-radius: 0px;
					border-radius: 0px;
					-webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
					-moz-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
					box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
					text-align: right;
					margin-left: 20px;
					//background-color: rgba(52,139,255,0.200000003);
					//border: 1px solid #34A2FF;
					border-radius: 4px;
					padding: 15px;

					&.articleContent {
						background-color: white;
					}

					&::after {
						clear: both;
						content: "";
						position: absolute;
						border-style: solid;
						width: 0;
						height: 0;
						top: 38px;
						right: -15px;
						border-width: 10px 0 10px 15px;
						//border-color: transparent transparent transparent #34A2FF;
						border-color: transparent transparent transparent #FFFFFF;
					}

					@media screen and (min-width: 960px) {
						&.inverted {
							float: right;
							text-align: left;
							margin-right: 20px;

							&::after {
								left: -30px;
								border-width: 10px 15px 10px 15px;
								border-color: transparent #34a2ff transparent transparent;
							}

							.body {
								img {
									float: right;
								}
							}
						}
					}

					header {
						color: $color-white;
						padding: 8px;
						margin: 0;
						font-size: 1.2em;
						//   background-color: $color-orange;
						overflow: hidden;
						text-align: center;
						-webkit-border-radius: 0px;
						-moz-border-radius: 0px;
						border-radius: 0px;
					}

					footer {
						padding: 0 15px 15px 15px;
						background-color: $color-white;
					}

					.body {
						display: inline-block;
						padding: 0 15px;

						img {
							float: left;
							margin: 0 15px 15px 0;
						}
					}
				}

				.badge {
					display: table;
					text-align: center;
					// cursor: none;
					font-weight: bold;
					-moz-box-shadow: 0 0 0 2px $headerColor,
						inset 0 2px 0 rgba(0, 0, 0, 0.1),
						0 3px 0 2px rgba(0, 0, 0, 0.15);
					-webkit-box-shadow: 0 0 0 2px $headerColor,
						inset 0 2px 0 rgba(0, 0, 0, 0.1),
						0 3px 0 2px rgba(0, 0, 0, 0.15);
					box-shadow: 0 0 0 2px $headerColor,
						inset 0 2px 0 rgba(0, 0, 0, 0.1),
						0 3px 0 2px rgba(0, 0, 0, 0.15);
					position: absolute;
					left: 50%;
					margin: 30px 0 0 -15px;
					border-radius: 50%;
					font-size: 0.75em;
					width: 30px;
					height: 30px;
					background: $color-white;
					word-break: break-word;
					word-spacing: 30000px;

					&.last {
						display: none;
						// background-color: $color-orange;
						width: 20px;
						height: 20px;
						margin: 0px 0px -20px -10px;
						position: relative;
						clear: both;
					}

					&.last:last-child {
						display: block;
					}

					span {
						display: table-cell;
						vertical-align: middle;
						color: $headerColor;
					}
				}
			}
		}
	}

	@media screen and (max-width: 959px) {
		#mtDtimeline.vertical {
			&::before {
				left: 50px;
			}

			section {
				header[class*="group"] {
					margin: 35px 12px;
				}

				.item {
					.badge {
						left: 50px;
					}

					article {
						max-width: 100%;
						min-width: 375px;
						width: auto;
						margin-left: 90px;
						text-align: left;
						margin-right: 20px;

						.body {
							display: inline-block;
							padding: 20px 10px;
						}

						&::after {
							content: "";
							left: -15px;
							border-width: 10px 15px 10px 0;
							border-color: transparent $color-white transparent transparent;
						}

						@media screen and (min-width: 960px) {
							&.inverted {
								margin-left: 20px;
							}
						}
					}
				}
			}
		}
	}

	/// timeline for mobile view
	#mtDtimeline.mobile {
		color: $color-white;
		//width: 350px;
		margin: 0 auto;
		background-color: rgba(255, 255, 255, 0.1);
		padding: 5px 15px;
		// background: -webkit-gradient(
		// 		linear,
		// 		left top,
		// 		left bottom,
		// 		from(#4853af),
		// 		to(#c33764)
		// 	)
		// 	fixed;
		background: linear-gradient(180deg, white 0%, #a7aeb9b8 52%, rgba(26, 54, 104, 0.3491771709) 100%, #c5cede 100%);



		nav {
			height: 50px;
			display: table;
			margin: 0 auto;

			a {
				display: inline;
				position: relative;
				margin: 0px;
				padding: 0px;
				text-decoration: none;
				font-size: 16px;
				padding: 10px;
				padding-top: 20px;
				color: $color-white;
				font-weight: bold;
				border-bottom: 5px solid transparent;

				&:hover {
					border-bottom: 2px solid $color-white;
					font-weight: normal;
				}
			}
		}

		.dvSection {
			width: 100%;
			display: flex;
			height: 100%;
			flex-direction: column;
			padding: 0;
		}

		section {
			width: 100%;
			display: flex;
			height: 100%;
			flex-direction: column;
			padding: 0;

			header[class*="group"] {
				font-size: 1.1em;
				color: #000;
				//margin: 30px auto 0 auto;
				text-align: center;
			}

			.item {
				display: inline-block;
				margin-top: 5px;
				min-height: 65px;
				width: 100%;

				article {
					//float: right;
					//width: 80%;
					height: 100%;
					border-bottom: 1px solid $color-white;
					padding-bottom: 15px;
					margin-bottom: 5px;

					header {
						font-size: 1.1em;
						margin-bottom: 5px;
					}

					.body {
						display: inline-block;
						text-align: justify;
						line-height: 20px;

						a {
							color: #cf1d2c !important;
							font-size: 1.3em;
						}
					}
				}

				.badge {
					float: left;
					font-size: 2em;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					width: 20%;
					line-height: 1;
					text-align: left;
					word-break: break-word;
					word-spacing: 30000px;

					&.last {
						display: none;
					}
				}
			}
		}
	}
}