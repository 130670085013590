// -----------------------------------------------------------------------------------------------------
// @ Body scroll lock
// -----------------------------------------------------------------------------------------------------
html,
body {
    // tD: dimciks 
    // display: flex;
    // flex: 1 0 auto;
    width: 100%;
    height: 100%;
    max-height: 100%;
    min-height: 100%;
    margin: 0;
	padding: 0;
	overflow-y: auto;
	overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    /* Safari compatibility */
    height: 100%;
    width: 100%;
    //transform: translate3d(0,0,0);
}

// -----------------------------------------------------------------------------------------------------
// @ Boxed body
// -----------------------------------------------------------------------------------------------------
body {

    // Boxed
    &.boxed #main{
        max-width: 80vw;
        margin: 0 auto;
        @media screen and (max-width:699px){
            max-width: 100%;
        }
        @media screen and (max-width:2599px) and (min-width:2400px){
            max-width: 70vw;
        } @media screen and (min-width:2600px){
            max-width: 60vw;
        }
    }

    &.boxedWithShadow{
		@media screen {
        	@include mat.elevation(8);
		}
    }
}

/*----------------------------------------------------------------*/
/*  @ Text rendering & box sizing
/*----------------------------------------------------------------*/
* {
    text-rendering: optimizeLegibility;
    -o-text-rendering: optimizeLegibility;
    -ms-text-rendering: optimizeLegibility;
    -moz-text-rendering: optimizeLegibility;
    -webkit-text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;

    &:before, &:after {
        box-sizing: border-box;
    }

    // Remove focus outline
    &:focus {
        outline: none;
    }
}

// -----------------------------------------------------------------------------------------------------
// @ Responsive images
// -----------------------------------------------------------------------------------------------------

img {
    //max-width: 100%;
    height: auto;
    vertical-align: top;
    border: none;
}

// -----------------------------------------------------------------------------------------------------
// @ Input
// -----------------------------------------------------------------------------------------------------
input {
    border: none;
    padding: 0 12px;
	background-color: #fff;
}