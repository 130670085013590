// Media step breakpoint mixin based on Angular Material lib
// $breakpoints: (
//     xs: 'screen and (max-width: 599px)',
//     sm: 'screen and (min-width: 600px) and (max-width: 959px)',
//     md: 'screen and (min-width: 960px) and (max-width: 1279px)',
//     lg: 'screen and (min-width: 1280px) and (max-width: 1919px)',
//     xl: 'screen and (min-width: 1920px) and (max-width: 5000px)',
//     lt-sm: 'screen and (max-width: 599px)',
//     lt-md: 'screen and (max-width: 959px)',
//     lt-lg: 'screen and (max-width: 1279px)',
//     lt-xl: 'screen and (max-width: 1919px)',
//     gt-xs: 'screen and (min-width: 600px)',
//     gt-sm: 'screen and (min-width: 960px)',
//     gt-md: 'screen and (min-width: 1280px)',
//     gt-lg: 'screen and (min-width: 1920px)'
// ) !default;

$breakpoints: (
    xs: 'screen and (max-width: 599px)',
    sm: 'screen and (min-width: 600px) and (max-width: 959px)',
    md: 'screen and (min-width: 960px) and (max-width: 1279px)',
    lg: 'screen and (min-width: 1280px) and (max-width: 1599px)',
    lx: 'screen and (min-width: 1600px) and (max-width: 1919px)',
    xl: 'screen and (min-width: 1920px)',
    lt-sm: 'screen and (max-width: 599px)',
    lt-md: 'screen and (max-width: 959px)',
    lt-lg: 'screen and (max-width: 1279px)',
    lt-lx: 'screen and (max-width: 1599px)',
    lt-xl: 'screen and (max-width: 1919px)',
    gt-xs: 'screen and (min-width: 600px)',
    gt-sm: 'screen and (min-width: 960px)',
    gt-md: 'screen and (min-width: 1280px)',
    gt-lg: 'screen and (min-width: 1600px)',
    gt-xl: 'screen and (min-width: 1920px)',
    iphone3: 'screen and (min-device-width: 320px) and (max-device-height: 480px) and (-webkit-device-pixel-ratio: 1)',
    iphone4: 'screen and (min-device-width: 320px) and (max-device-height: 480px) and (-webkit-device-pixel-ratio: 2)',
    iphone5: 'screen and (min-device-width: 320px) and (max-device-height: 568px) and (-webkit-device-pixel-ratio: 2)',
    iphone6-6s-7-8: 'screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2)',
    iphone6p-6sp-7p-8p: 'screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3)',
    iphoneX-XS-11pro: 'screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3)',
    iphoneX-XR-11: 'screen and (min-device-width : 414px) and (max-device-height : 896px) and (-webkit-device-pixel-ratio : 2)',
    iphoneXSMax-11proMax: 'screen and (min-device-width : 414px) and (max-device-height : 896px) and (-webkit-device-pixel-ratio : 3)',
    ipad:'screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-device-pixel-ratio : 1)',
    tablet:'screen and (max-width: 1024px)',
	pool-display-custom: 'screen and (min-width: 1300px) and (max-width: 1460px), (min-width: 961px) and (max-width: 1112px)',
);

// Re-map the breakpoints for the helper classes
// $helper-breakpoints: (
//     xs: null,
//     sm: 'gt-xs',
//     md: 'gt-sm',
//     lg: 'gt-md',
//     xl: 'gt-lg'
// );
$helper-breakpoints: (
    xs: null,
    sm: 'gt-xs',
    md: 'gt-sm',
    lg: 'gt-md',
    lx: 'gt-lg',
    xl: 'gt-lx'
);
@mixin media-breakpoint($breakpointName) {

    $mediaQuery: map-get($breakpoints, $breakpointName);

    @if ($mediaQuery == null) {
        @content
    } @else {
        @media #{$mediaQuery} {
            @content
        }
    }
}
