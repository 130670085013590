@mixin register-theme($theme) {

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $is-dark: map-get($theme, is-dark);
    $green:#318b7f;
    $purple: purple; 

    register {
        /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */
        /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */
        /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */
        /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */
        .green-progress .mat-progress-bar-fill::after { background-color: ($green) !important;}
        // .green-progress .mat-progress-bar-buffer { background: ($purple) !important;} 
        #register {

            #register-form-wrapper {

                #register-form {
                    // @if ($is-dark) {
                    //     background: mat-color($fuse-navy, 600);
                    // } @else {
                    //     background: map-get($background, card);
                    // }                    

                    .separator {
                        color: map-get($foreground, divider);

                        .text {

                            &:before,
                            &:after {
                                border-top-color: map-get($foreground, divider);
                            }
                        }
                    }
                }
            }
        }
    }
}